.c-checkbox-word {
    display: block;

    margin-right: 1rem;

    &:first-of-type {
        margin-top: 3rem;
    }

    @include mq(s-tablet) {
        margin-top: 3rem;
        display: inline-block;
    }

    @include mq(desktop) {
        margin-right: 3rem;

        &:last-of-type {
            margin-right: 0;
        }
    }

    sup {
        margin-right: 5px;
    }

    label {
        position: relative;

        display: inline-block;

        padding: 1rem 3px;

        cursor: pointer;
        transition: color 0.3s ease;

        color: $color-primary;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;

        visibility: hidden;
    }

    input:checked + .c-checkbox-word__label {
        color: $color-white;
    }

    label:hover {
        color: $color-white;
    }
}

.c-checkbox-word__label {
    text-transform: uppercase;
    pointer-events: none;

    transition: color 0.3s ease 0.4s;
}
