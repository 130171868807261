.c-apploader {
    position: fixed;
    z-index: z-index(loader);
    top: 0;
    left: 0;

    display: flex;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;
}

.c-apploader__bg {
    position: absolute;
    z-index: z-index(loader) - 1;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
}

.c-apploader__inner {
    position: relative;

    width: 80%;

    @include mq(l-tablet) {
        width: 33.3333%;
        height: 0;
        padding-bottom: 33.3333%;
    }
}

.c-apploader.is-leaving {
    .c-frame--loader-bg {
        .c-frame__svg {
            display: none;
        }
        .c-frame__content {
            transform: translateY(30px);
            opacity: 0;
            transition: all 0.5s $expo-ease-in 0.8s;
        }
    }

    .c-frame--loader {
        .c-frame__percentage {
            transform: translateY(30px);
            opacity: 0;
            transition: all 0.5s $expo-ease-in 0.1s;
        }
    }

}

.c-apploader.is-loading {
    .c-frame--loader {
        .c-frame__svg {
            display: block;
        }
    }
}
