.c-draggable {
    @include boost-performance;

    height: 100%;
    perspective: 1900px;
    //background-color: rgba(255, 0, 255, 1);

    cursor: -moz-grab !important;
    cursor: -webkit-grab !important;

    &.is-grabbing {
        cursor: -moz-grabbing !important;
        cursor: -webkit-grabbing !important
    }
}