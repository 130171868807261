.o-page--vini {
    position: absolute;
    z-index: z-index(above);
    top: 0;
    left: 0;

    overflow: hidden;

    width: 100%;
    height: 100vh;

    background-color: $color-black;
}

.o-page--winery,
.o-page--feudistudi,
.o-page--contact,
.o-page--newsletter,
.o-page--privacy,
.o-page--terms,
.o-page--group {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > [data-scrollbar] {
        height: 100vh;
        z-index: 1;
    }
}

.o-page--art,
.o-page--store-locator,
.o-page--contact,
.o-page--newsletter {
    [data-scrollbar] {
        height: 100vh;
    }

    .scroll-content {
        height: calc(100vh - 80px);
    }
}

.o-page--not-found {
    height: 80vh;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    h1 {
        text-transform: uppercase;
    }
}

.o-page__swipeable {
    min-height: 100vh;
}