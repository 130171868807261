.c-link {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        top: 110%;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: $color-primary;

    }

    .no-touchevents & {
        &::after {
            transform: scale(0, 1);
            transform-origin: 100% 50%;
            transition: transform 0.5s $power3-ease-in-out;
        }

        &:hover {
            &::after {
                transform: scale(1, 1);
                transform-origin: 0 50%;
            }
        }
    }
}