.c-wine-detail-panel {
    @include boost-performance(false);
    will-change: transform;

    position: fixed;
    z-index: z-index(above) + 1;
    top: 100%;
    bottom: 0;
    left: 0;

    overflow: hidden;

    width: 100%;
    height: 100%;

    content: '';

    color: $color-primary;
    background-color: $color-white;

    transition: transform 1.5s $power3-ease-in-out 0s;

    @include mq(l-tablet) {
        top: 0;
        left: 100%;
        width: 50%;
    }

    h1 {
        margin-top: 0;
        margin-bottom: 3rem;

        text-transform: uppercase;
    }
}

.c-wine-detail-panel__inner {
    height: calc(100% - 35px);

    @include mq(s-tablet) {
        height: calc(100% - 77px);
    }

    @include mq(l-tablet) {
        height: 100%;
    }

    [data-scrollbar] {
        height: 100%;
    }

    .scroll-content {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 76px);
    }
}

.c-wine-detail-panel__desc {
    height: 100%;
    padding-bottom: 76px;

    &::before {
        position: fixed;
        z-index: z-index(above);
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: $color-primary;
        opacity: 1;

        transition: opacity 0.5s ease-in-out;

        @include mq(l-tablet) {
            display: none;
        }
    }

    &:not(.is-top) {
        .c-btn--wine-detail-close .c-btn--back__label {
            @include mq($until: l-tablet) {
                color: $color-white;
            }
        }
        .c-btn--back {
            svg {
                * {
                    @include mq($until: l-tablet) {
                        fill: $color-white;
                    }
                }
            }
        }
    }

    &.is-top {
        &::before {
            opacity: 0;
        }
    }
}

.c-wine-detail-panel__content {
    padding: 20% 8vw;
    flex: 1 0 auto;

    @include mq(l-tablet) {
        padding: 4vw;
    }
}

.c-wine-detail-panel__gradient {
    position: absolute;
    z-index: z-index(above) + 2;
    left: 0;

    width: calc(100% - 10px);
    height: 10%;

    content: '';
}

.c-wine-detail-panel__gradient--top {
    top: 0;
    pointer-events: none;

    @include mq(l-tablet) {
        background: linear-gradient(to bottom,  rgba($color-white,1) 0%,rgba($color-white,0) 100%);
    }
}

.c-wine-detail-panel__gradient--bottom {
    top: auto;
    bottom: 76px;
}

.c-wine-detail-panel.is-open {
    transition: transform 1.5s $power3-ease-in-out 0s;
    transform: translateY(#{calc(-100% + 35px)});

    @include mq(s-tablet) {
        transform: translateY(#{calc(-100% + 77px)});
    }

    @include mq(l-tablet) {
        transition: transform 1.5s $power3-ease-in-out 0.5s;
        transform: translateX(-100%);
    }
}