$c-input-checkbox-size: 24px;
$c-input-checkbox-text-font-size: ( null: 12px);
$c-input-checkbox-text-gutter: 2px;
.c-input-checkbox {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center; // > * {
    //     flex: 0 0 auto;
    // }
}

.c-input-checkbox__wrapper {
    position: relative;
    width: $c-input-checkbox-size;
    height: $c-input-checkbox-size;
    flex: 0 0 auto;
}

.c-input-checkbox__wrapper>* {
    @include full-abs;
}

.c-input-checkbox__wrapper .c-form-field__field {
    opacity: 0;
    padding: 0;
    z-index: z-index(base) + 1;
}

.c-input-checkbox__wrapper .c-input-checkbox__hack {
    background: rgba($color-white, 0.22);
    z-index: z-index(behind);
}

.c-input-checkbox__hack {
    border: 0 solid rgba($color-white, 0.5);
    transition: border 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.c-input-checkbox__hack::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: $c-input-checkbox-size / 3;
    height: $c-input-checkbox-size / 3;
    transform: translate($c-input-checkbox-size / -6, $c-input-checkbox-size / -6) scale(0);
    background: $color-primary;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.c-input-checkbox__wrapper .c-form-field__field:checked+.c-input-checkbox__hack::before {
    transform: translate($c-input-checkbox-size / -6, $c-input-checkbox-size / -6) scale(1);
}

.c-input-checkbox__label-outer {
    position: relative;
    padding-left: 1rem;
    text-transform: uppercase; // max-width: 60vw;
    flex: 0 1 auto;
}

.c-input-checkbox__text {
    @include mq-prop(font-size, $c-input-checkbox-text-font-size);
    position: relative;
    z-index: z-index(base) + 2; // opacity: 0.5;
    line-height: 1.4;
    text-transform: none;
    letter-spacing: 1px;
    color: gray;
    a {
        display: block;
        color: white;
        position: relative; // opacity: 0.8;
        transition: color 0.2s ease;
        @include mq(s-tablet) {
            display: inline;
        }
        &:hover {
            color: $color-primary;
        }
    }
}

.c-input-checkbox__text+.c-input-checkbox__text {
    @include mq(s-tablet) {
        margin-left: 0.3rem;
    }
}

.c-input-checkbox__link {
    display: block;
    position: relative;
    opacity: 0.8;
    transition: color 0.2s ease;
    @include mq(s-tablet) {
        display: inline;
    }
}

.no-touchevents .c-input-checkbox__link:hover {
    color: $color-primary;
}

.c-input-checkbox__label-outer::before {
    @include full-abs;
    z-index: z-index(behind);
    left: 1rem;
    top: auto;
    bottom: -2px;
    height: $c-input-checkbox-text-gutter;
    width: calc(100% - 1rem);
    content: '';
    background: rgba($color-error, 0.8);
    transition: transform 0.3s ease;
    transform-origin: left center;
    transform: scale(0, 1);
}

.c-input-checkbox.is-invalid .c-input-checkbox__label-outer::before {
    transform: scale(1, 1);
}

.c-input-checkbox.is-invalid .c-input-checkbox__hack {
    border-width: $c-input-checkbox-text-gutter;
}