.c-checkbox-group {
    position: relative;
}

.c-checkbox-group--feudistudi-map {
    @include mq(l-tablet) {
        margin-left: 20%;
    }
}

.c-checkbox-group__indicator {
    @include boost-performance();
    will-change: transform, width;

    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;

    width: 0;
    height: 3px;

    content: '';

    background-color: $color-primary;

    transition: all 0.9s $expo-ease-in-out;
}
