.c-leaf {
    position: fixed;
    z-index: z-index(modal);
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    background-color: $color-black;

    visibility: hidden;
    opacity: 0;

    transition: visibility 0s 1.5s, opacity 1s $power3-ease-out 0.5s;

    [data-scrollbar] {
        z-index: 1;

        height: 100vh;
    }

    .c-parallax-bg__img {
        opacity: 0;
        transition: opacity 1s $power3-ease-out 0s;
    }
}

.c-leaf__inner {
    opacity: 0;
}

.c-leaf.is-open {
    visibility: visible;
    opacity: 1;

    transition: visibility 0s 0s, opacity 1s $power3-ease-out 0s;

    .c-parallax-bg__img {
        opacity: 1;
        transition: opacity 1s $power3-ease-out 0.5s;
    }
}