.o-form-container {
    margin: 3rem $margin-left-base 12rem;

    @include mq(s-tablet) {
        margin: 0;
    }
}

.o-form__row {
    width: 100%;

    @include mq(l-tablet) {
        display: flex;
        flex-direction: row;

        align-items: flex-start;
        justify-content: center;
    }
}

.o-form__row--left {

    @include mq(l-tablet) {
        justify-content: flex-start;
    }
}

.o-form__field {
    @include mq(l-tablet) {
        width: 50%;

        &:nth-child(1) {
            padding-right: 3rem;
        }

        &:nth-child(2) {
            padding-left: 3rem;
        }
    }
}

.o-form__field--large {
    @include mq(l-tablet) {
        width: 66.6666%;
    }
}

.o-form__field--small {
    @include mq(l-tablet) {
        width: 33.3333%;
    }
}

.o-form__field--full {
    @include mq(l-tablet) {
        width: 100%;
        &,
        &:nth-child(1),
        &:nth-child(2) {
            padding: 0;
        }
    }
}

.o-form__submit {
    background-color: transparent;
    border: 0;
    color: $color-primary;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;

    float: right;

    cursor: pointer;

    &:disabled {
        color: $color-white;
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.o-form__submit--winery {
    @include mq(l-tablet) {
        margin-right: $margin-left-contained-element * 2;
    }
}