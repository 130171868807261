.c-address {
    padding-top: 2rem;
    line-height: 1.7;

    margin-left: $margin-left-base;

    transition: opacity 0.3s ease;

    a {
        transition: color 0.3s ease;

        &:hover {
            color: $color-primary;
        }
    }

    @include mq(s-tablet) {
        float: left;
        width: 50%;
        margin-left: $margin-left-base;
    }

    @include mq(l-tablet) {
        width: 33.3333%;
        margin-left: 0;
    }

    @include mq(desktop) {
        //margin-left: $margin-left-base;
    }

    &.is-hide {
        opacity: 0;
    }
}