.o-group-map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    margin: floor($margin-left-base / 2);
    margin-top: 5rem;

    @include mq(desktop) {
        flex-direction: row;
        margin: 3rem $margin-left-base 0 $margin-left-indented;
    }

    @include mq(wide) {
        margin: 6rem $margin-left-base 0 $margin-left-indented;
    }
}

.o-group-map__map {
    width: 100%;
    max-width: 550px;
    margin-bottom: 2rem;

    @include mq(desktop) {
        width: 60%;
    }
}

.o-group-map__side {
    position: relative;

    @include mq(desktop) {
        width: 40%;
        padding-left: 2rem;
    }
}

.o-group-map__address-container {
    &.is-active {
        z-index: 2;
    }

    @include mq(desktop) {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 2rem;
        width: 100%;
    }
}

.o-group-map__address {
    transform: translate3d(10%, 0, 0);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s 0s, transform 0s 0.3s $power3-ease-out;

    display: none;

    @include mq(s-tablet) {
        width: 60%;
        margin-left: 12vw;
    }

    @include mq(desktop) {
        display: block;
        width: auto;
        margin-left: 0;
    }

    .o-group-map__address-container.is-active & {
        display: block;
        transform: translate3d(0, 0, 0);
        visibility: visible;
        opacity: 1;
        transition: visibility 0s 0s, opacity 0.7s 0.3s, transform 0.7s 0.3s $power3-ease-out;

        &:nth-child(2) {
            transition: visibility 0s 0s, opacity 0.7s 0.5s, transform 0.7s 0.5s $power3-ease-out;
        }

        &:nth-child(3) {
            transition: visibility 0s 0s, opacity 0.7s 0.7s, transform 0.7s 0.7s $power3-ease-out;
        }
    }

    @include mq(wide) {
        margin-top: 4rem;
    }

    figure {
        margin: 0;
    }
}

.o-group-map__logo {
    margin: 0 $margin-left-base;
    max-width: 150px;
    max-height: 60px;


    @include mq(s-tablet) {
        margin: auto;
        max-width: 100px;
        max-height: 100px;
    }

    @include mq(wide) {
        max-height: 80px;
        max-width: 130px;
    }
}

.o-group-map__desc {
    max-width: 250px;
    margin-top: 2rem;

    line-height: 2;
}

// DUBL
.o-group-map__address--campania-01 {
    .o-group-map__logo {
        @include mq(wide) {
            max-height: 60px;
        }
    }
}

// Ognissole
.o-group-map__address--puglia-00 {
    .o-group-map__logo {
        @include mq(wide) {
            max-height: 100px;
        }
    }
}


// D'orta de conciliis
// Federico Graziani
.o-group-map__address--campania-02,
.o-group-map__address--sicilia-00,
.o-group-map__address--basilicata-00 {
    .o-group-map__logo {
        @include mq(wide) {
            max-width: 170px;
        }
    }
}