.o-search {
    position: fixed;
    z-index: z-index(modal);

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    background-color: $color-black;
    text-align: center;

    visibility: hidden;
    clip-path: circle(0 at 92vw 20px);
    transition: visibility 0s 1.5s, clip-path 1s $expo-ease-in-out 0s;

    @include mq(l-tablet) {
        clip-path: circle(0 at 92vw 40px);
    }

    .c-plus--search {
        z-index: 3;
        opacity: 0;
        transform: translateY(20px) rotate(0deg);
        top: 10px;
        right: 10px;

        transition: all 0.5s $power3-ease-out 0s;

        @include mq(l-tablet) {
            right: 130px;
        }
    }

    > [data-scrollbar] {
        height: 100vh;
        z-index: 1;
    }

    .scroll-content {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

.o-search__shadow {
    position: fixed;
    z-index: 1;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 33vh;

    opacity: 0;

    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 49%, rgba(0, 0, 0, 0) 100%);
}

.o-search__input-outer {
    position: absolute;
    z-index: 2;
    width: 80%;
    height: 100%;

    left: 0;
    right: 0;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    transform: translateY(30px);
    opacity: 0;

    transition: all 0.5s $power3-ease-in-out;

    pointer-events: none;
}

.o-search__inner {
    position: relative;

    height: 100%;
    width: 100%;

    padding-top: 25vh;

    visibility: hidden;
    opacity: 0;
    transform: translateY(30px);

    transition: visibility 0s 0s, opacity 0.7s ease 0.7s, transform 0.7s $power3-ease-in;

    @include mq(l-tablet) {
        margin-top: 1rem;
    }

    @include mq(desktop) {
        padding-top: 0;
        transform: none;

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

.o-search__row {
    width: 80%;
    margin: 1rem auto;
}

.o-search__item {
    float: left;
    width: 50%;
    display: inline-block;

    @include mq(s-tablet) {
        margin-bottom: 2rem;
    }

    @include mq(desktop) {
        width: 16.666%;
        opacity: 0;
    }

    img {
        max-height: 300px;
    }

    figcaption {
        margin-top: 1rem;
        text-transform: uppercase;
    }
}

.o-search__label {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        top: auto;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-primary;

        transform: scale(0, 1);
        transform-origin: 100% 50%;
        transition: transform 0.5s $power3-ease-in-out;

        @include mq($until: desktop) {
            display: none;
        }
    }
}

.o-search__item:hover {
    .o-search__label {
        &::after {
            transform: scale(1, 1);
            transform-origin: 0 50%;
        }
    }
}

.o-search.is-open {
    visibility: visible;

    transition: visibility 0s 0s, clip-path 1s $expo-ease-in-out 0s;
    clip-path: circle(#{sqrt(20000)}vmax at 0 0);

    .o-search__input-outer {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.5s $power3-ease-in-out 0.3s;
    }

    .o-search__shadow {
        opacity: 1;
        transition: opacity 0.5s $power3-ease-in-out 0.8s;
    }

    .c-plus--search {
        transform: translateY(0) rotate(45deg);
        opacity: 1;

        transition: all 0.7s $power3-ease-out 0.2s;

        &:hover {
            transform: translateY(0) rotate(135deg);
        }
    }
}

.o-search.is-open.has-results {
    .o-search__input-outer {
        transform: translateY(-33%)
    }

    .o-search__inner {
        visibility: visible;
        opacity: 1;
    }
}