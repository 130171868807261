@keyframes animationOneReverse {
    0% {
        transform: rotate(315deg);
    }
    25% {
        transform: rotate(360deg);
    }
    50%, 100% {
        transform: rotate(0deg);
    }
}

@keyframes animationOne {
    0%, 50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(315deg);
    }
}

@keyframes animationTwoReverse {
    0% {
        transform: rotate(405deg);
    }
    25% {
        transform: rotate(450deg);
    }
    50%, 100% {
        transform: rotate(0deg);
    }
}

@keyframes animationTwo {
    0%, 50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(450deg);
    }
    100% {
        transform: rotate(405deg);
    }
}

@keyframes animationBurgerTopReverse {
    0%, 50% {
        transform: translateY(floor($burger-height / 2));
        opacity: 0;
    }
    51% {
        transform: translateY(floor($burger-height / 2));
        opacity: 1;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes animationBurgerTop {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        transform: translateY(floor($burger-height / 2));
        opacity: 1;
    }
    51%, 100% {
        transform: translateY(floor($burger-height / 2));
        opacity: 0;
    }
}

@keyframes animationBurgerBottomReverse {
    0%, 50% {
        transform: translateY(floor($burger-height / 2 * -1));
        opacity: 0;
    }
    51% {
        transform: translateY(floor($burger-height / 2 * -1));
        opacity: 1;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes animationBurgerBottom {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        transform: translateY(floor($burger-height / 2 * -1));
        opacity: 1;
    }
    51%, 100% {
        transform: translateY(floor($burger-height / 2 * -1));
        opacity: 0;
    }
}

@keyframes dotPulse {
    from {
        transform: scale(0.2, 0.2);
        opacity: 0.8;
    }
    to {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes dotPulseCenter {
    0% {
        transform: scale(0.2, 0.2) translate3d(-50%, -50%, 0);
        opacity: 0.8;
    }
    100% {
        transform: scale(0.8, 0.8) translate3d(-50%, -50%, 0);
        opacity: 0;
    }
}

@keyframes dotPulseGrowing {
    0% {
        transform: scale(1, 1);
        opacity: 0.8;
    }
    100% {
        transform: scale(5, 5);
        opacity: 0;
    }
}

@keyframes btnBounce {
    0% {
        transform: scale(1, 1);
    }

    30% {
        transform: scale(0.95, 0.92);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes infinite-spinning {
    0% {
        transform: rotate(0deg);
        stroke-dashoffset: 80px;
    }

    50% {
        transform: rotate(360deg);
        stroke-dashoffset: 130px;
    }

    100% {
        transform: rotate(720deg);
        stroke-dashoffset: 80px;
    }
}

@keyframes arrowLeft {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    50% {
        transform: translate3d(-25px, 0, 0);
        opacity: 0;
    }
    51% {
        transform: translate3d(85px, 0, 0);
        opacity: 0;
    }
    100% {
        transform: translate3d(60px, 0, 0);
        opacity: 1;
    }
}

@keyframes arrowLeftBack {
    0% {
        transform: translate3d(60px, 0, 0);
        opacity: 1;
    }

    30% {
        transform: translate3d(30px, 0, 0);
        opacity: 0;
    }

    50% {
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes arrowRight {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    30% {
        transform: translate3d(25px, 0, 0);
        opacity: 0;
    }
    50% {
        transform: translate3d(25px, 0, 0);
        opacity: 0;
    }
    100% {
        transform: translate3d(80px, 0, 0);
        opacity: 1;
    }
}

@keyframes arrowRightBack {
    0% {
        transform: translate3d(80px, 0, 0);
        opacity: 1;
    }
    30% {
        transform: translate3d(70px, 0, 0);
        opacity: 0;
    }
    50% {
        transform: translate3d(-25px, 0, 0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0px, 0, 0);
        opacity: 1;
    }
}

@keyframes whiteBlur {
    from {
        text-shadow: 0 0 72px white;
        color: transparent;
    }
    to {
        text-shadow: 0;
        color: black;
    }
}

@keyframes burgerHover {
    0% {
        transform: scale(1, 1);
        transform-origin: 0 50%;
    }
    50% {
        transform: scale(0, 1);
        transform-origin: 0 50%;
    }
    51% {
        transform: scale(0, 1);
        transform-origin: 100% 50%;
    }
    100% {
        transform: scale(1, 1);
        transform-origin: 100% 50%;
    }
}

@keyframes btnVerticalLabelFlip {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    30% {
        transform: translate3d(-15px, 0, 0);
        opacity: 0;
    }
    31% {
        transform: translate3d(15px, 0, 0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0px, 0, 0);
        opacity: 1;
    }
}

/**
 * Circle Loader
 */
@keyframes loader-1-1 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-1-2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(220deg);
    }
}

@keyframes loader-1-3 {
    0% {
        transform: rotate(-140deg);
    }
    50% {
        transform: rotate(-160deg);
    }
    100% {
        transform: rotate(140deg);
    }
}

@keyframes arrow-left {
    0% {
        transform: rotate(-45deg);
    }
    30% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(-45deg);
    }
}

@keyframes arrow-right {
    0% {
        transform: rotate(45deg);
    }
    30% {
        transform: rotate(20deg);
    }
    100% {
        transform: rotate(45deg);
    }
}