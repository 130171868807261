.o-footer {
    margin: 6rem $margin-left-base 6rem;
    text-transform: uppercase;
    font-size: rem(12px);
    position: relative;
    z-index: z-index(above) + 5;

    max-width: 1400px;

    @include mq(s-tablet) {
        font-size: rem(10px);
        margin: 6rem floor($margin-left-base / 2) 3rem;
    }

    @include mq(desktop) {
        font-size: rem(12px);
        width: 90vw;
        margin: 30vh auto 3rem;
    }

    li {
        letter-spacing: 1px;
    }
}

.o-footer__line {
    border: 0;
    height: 1px;
    background-color: $color-primary;
    margin: 2rem 0;
}

.o-footer__menu {
    @include mq(s-tablet) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.o-footer__under__menu {
    @include mq(s-tablet) {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.o-footer__side {
    padding: 0 1rem;
    margin-top: 3rem;

    @include mq(s-tablet) {
        margin-top: 0;
    }
}

.o-footer__credits {
    margin-top: 3rem;

    @include mq(desktop) {
        margin-top: 0;
    }

    @include mq(wide) {
        margin-left: 3rem;
    }
}

.logofooter-side-img{
    margin-top: -13px;
    max-width: 50px;
    max-height: 50px;
}

.o-footer--fixed {
    @include mq(s-tablet) {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;

        margin: 0 floor($margin-left-base / 2) 1rem;
    }

    @include mq(desktop) {
        margin: 0 auto 3rem;
    }

    @include mq(wide) {
        max-width: none;
    }
}

.o-footer__top {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
}