.o-app__sidebar {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.o-app__swipeable {
    position: absolute;
    width: 100%;
    height: 100%;
}