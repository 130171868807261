.c-europe-disclaimer {
    display: inline-block;
    width: 230px;

    margin-top: 1rem;

    @include mq(desktop) {
        margin-top: 0;
        margin-left: 1rem;
    }
}

.c-europe-disclaimer__inner {
    display: flex;
}

.c-europe-disclaimer__figure {
    margin: 0;
    margin-right: 10px;
}

.c-europe-disclaimer__text {
    text-transform: none;
    font-size: rem(9px);
    line-height: 1;

    p {
        margin-top: 0;
    }
}