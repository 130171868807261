.c-footer-btn {
    position: relative;

    width: 100%;
    margin-top: 10vh;
    padding: 2rem 1rem;

    cursor: pointer;
    text-transform: uppercase;

    color: $color-primary;
    border: 0;
    border-radius: 0;
    background-color: $color-white;

    &::after {
        position: absolute;
        z-index: z-index(base);
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: '';
        transform: skewX(20deg) translate3d(-105%, 0, 0);
        transform-origin: 100% 50%;
        transition: transform 1s $power3-ease-out;

        background-color: darken($color-white, 5%);
    }

    &:hover {
        &::after {
            transform: skewX(0deg) translate3d(0, 0, 0);
            transition: transform 1.7s $power3-ease-out;
        }
    }
}

.c-footer-btn__label,
.c-footer-btn__wine {
    position: relative;
    z-index: z-index(above);
    display: block;
}


.c-footer-btn__label {
    font-size: rem(12px);
}

.c-footer-btn__wine {
    font-size: rem(36px);
}
