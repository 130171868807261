.c-thankyou {
    position: absolute;
    z-index: z-index(menu);
    top: -1rem;
    left: 0;
    width: 100%;
    height: calc(100% + 2rem);

    background-color: $color-black;

    pointer-events: none;
    opacity: 0;
    visibility: hidden;

    transition: visibility 0s 0.7s, opacity 0.7s 0s;

    &.is-visible {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;

        transition: visibility 0s 0s, opacity 0.7s 0s;
    }
}

.c-thankyou__line1 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: rem(20px);
    margin-top: 80px;

    @include mq(desktop) {
        font-size: rem(36px);
    }
}

.c-thankyou__line2 {
    text-transform: uppercase;
}

.c-thankyou--newsletter {
    .c-thankyou__line1 {
        @include mq(desktop) {
            margin-top: 19vh;
        }
    }
}

.c-thankyou--bookavisit {
    .c-btn--thankyou-close {
        position: absolute;
        right: 0;
        top: 1rem;

        @include mq(desktop) {
            top: 3rem;
        }
    }
}