.c-wine-menu {
    position: fixed;
    z-index: z-index(above) + 2;
    top: auto;
    bottom: 0;
    left: 0;

    width: 100%;

    overflow-x: auto;
}

.c-wine-menu__inner {
    position: relative;
    left: 0;

    display: table;
    border-top: 1px solid rgba($color-white, 0.1);
    background-color: $color-black;

    width: 100%;
}

.c-wine-menu__list {
    display: table-cell;
}

.c-wine-menu__button {
    display: block;

    margin: -1px auto 0;
    padding: $wine-menu-padding;

    white-space: nowrap;

    cursor: pointer;
    transition: opacity 0.3s ease;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;

    opacity: 0.5;
    color: white;
    border: 0;
    border-top: 3px solid transparent;
    background-color: transparent;

    font-size: rem(12px);

    &:hover {
        opacity: 1;
    }
}

.c-wine-menu__indicator {
    position: absolute;
    z-index: z-index(above);
    content: '';
    top: 0;
    left: 0;
    width: 100px;
    height: 3px;
    background-color: $color-primary;

    transition: all 1.5s $power3-ease-in-out;
}

.c-wine-menu__list.is-active {
    .c-wine-menu__button {
        opacity: 1;
    }
}
