.c-wine-slider {
    position: absolute;
    z-index: z-index(above);
    top: 0;
    left: 0;

    width: auto;
    height: 100%;
}

.c-wine-slider__inner {
    @include boost-performance();
    will-change: transform;

    position: relative;

    display: table;
    overflow: hidden;

    width: auto;
    height: 100%;
}

.c-wine-slider__item {
    @include boost-performance();
    will-change: transform;

    display: table-cell;

    height: 100%;

    vertical-align: middle;
}
