.c-frame {
    position: relative;

    width: 100%;
    height: 0;
    padding-bottom: 100%;

    text-transform: uppercase;
}

.c-frame__line {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transition: transform 0.5s ease-in-out;

    background-color: $color-primary;

    will-change: transform;
}

.c-frame__line.c-frame__line--top,
.c-frame__line.c-frame__line--bottom {
    height: 2px;
}

.c-frame__line.c-frame__line--left,
.c-frame__line.c-frame__line--right {
    width: 2px;
}

.c-frame__line.c-frame__line--bottom {
    top: auto;
    bottom: 0;

    transform-origin: 0 50%;
}

.c-frame__line.c-frame__line--right {
    right: 0;
    left: auto;

    transform-origin: 0 100%;
}

.c-frame__line.c-frame__line--left {
    transform-origin: 0 0;
}

.c-frame__line.c-frame__line--top {
    transform-origin: 100% 0;
}

.c-frame__content {
    padding: 3%;
    line-height: 1;

    transform: translateY(30px);
    opacity: 0;

    transition: all 0.5s $power3-ease-in-out;
}

.c-frame__svg {
    position: absolute;

    display: none;

    width: 100%;
    height: 100%;

    transform: rotate(180deg);
}

.c-frame__rect {
    position: absolute;

    width: 100%;
    height: 100%;

    fill: transparent;
    stroke: $color-primary;
    stroke-width: 2px;
}

.c-frame.is-text-visible {
    .c-frame__content {
        opacity: 1;
        transform: translateY(0);
    }
}


/**
 * MODIFIERS
 */
.c-frame--coverslider {
    position: absolute;
    top: 50%;

    transform: translateY(-50%);

    text-shadow: 6px 6px 2px rgba(0, 0, 0, 0.2);

    margin: 10px;
    width: calc(100% - 20px);
    padding-bottom: calc(100% - 20px);

    @include mq(l-tablet) {
        margin: 0;
        width: 100%;
        padding-bottom: 100%;
    }

    .c-frame__line {
        border: 2px solid $color-white;
        outline: 1px solid transparent;

        @include mq(l-tablet) {
            border: 3px solid $color-white;
        }
    }

    .c-frame__content {
        font-size: 11vw;

        @include mq(l-tablet) {
            font-size: 5vw;
        }

        & p {
            margin: 0;
        }
    }
}

.c-frame--menu-item {
    .c-frame__content {
        padding: 0;
    }
}

.c-frame--loader,
.c-frame--loader-bg {
    display: block;

    position: absolute;
    top: 50%;
    left: 50%;

    transform-origin: 50% 50%;

    color: $color-primary;

    transform: translate(-50%, -50%) scale(0.7, 0.7);

    font-size: 13vw;

    @include mq(l-tablet) {
        transform: translate(-50%, -50%) scale(0.3, 0.3);

        font-size: 5.8vw;
    }

    .c-frame__line {
        border: 2px solid $color-primary;

        @include mq(l-tablet) {
            border: 3px solid $color-primary;
        }
    }

    .c-frame__percentage {
        position: absolute;
        top: 100%;
        left: 0;

        padding: 5% 0;
        color: lighten($color-black, 10%);
    }

}

.c-frame--loader {
    .c-frame__svg {
        display: none;
    }
}

.c-frame--menu-item {
    .c-frame__svg {
        display: block;
    }
}

.c-frame--loader-bg {
    .c-frame__svg {
        display: block;
    }

    .c-frame__rect {
        stroke: lighten($color-black, 10%);
        stroke-width: 2px;
    }

    .c-frame__content {
        color: lighten($color-black, 10%);
        transition: color 0.3s ease;
    }
}

.c-frame--is-ready {
    .c-frame__content {
        color: $color-primary;
    }
}