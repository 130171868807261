.c-burger {
    @include mq-prop(margin, $topbar-height, topbar-button-margin);
    position: relative;
    perspective: 700px;

    float: left;

    width: $burger-width + ($burger-gutter * 2);
    height: $burger-height  + ($burger-gutter * 2);

    padding: 0;

    cursor: pointer;

    border: 0;
    background-color: transparent;

    line-height: $burger-height;
}

.c-burger__input {
    display: block;
    position: absolute;
    z-index: z-index(menu);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    cursor: pointer;
}

.c-burger__label {
    position: relative;

    display: block;

    width: $burger-width + $burger-gutter * 2;
    height: $burger-height  + $burger-gutter * 2;

    cursor: pointer;

    pointer-events: none;

    background: transparent;
}

/* Exit Icon */
.c-burger__label:before,
.c-burger__input:checked + .c-burger__label:before {
    position: absolute;
    top: calc(50% - 1px);
    left: $burger-gutter;

    width: $burger-width;
    height: 2px;

    content: '';

    border-radius: 0;
    background: $color-white;
}

.c-burger__label:before {
    animation: animationOneReverse 1s ease forwards;
}

.c-burger__input:checked + .c-burger__label:before {
    animation: animationOne 1s ease forwards;
}

.c-burger__label:after,
.c-burger__input:checked + .c-burger__label:after {
    position: absolute;
    top: calc(50% - 1px);
    left: $burger-gutter;

    width: $burger-width;
    height: 2px;

    content: '';

    border-radius: 0;
    background: $color-white;
}

.c-burger__label:after {
    animation: animationTwoReverse 1s ease forwards;
}

.c-burger__input:checked + .c-burger__label:after {
    animation: animationTwo 1s ease forwards;
}

/* Burger Icon */
.c-burger__label .c-burger__burger:before {
    position: absolute;
    top: $burger-gutter;
    left: $burger-gutter;

    width: $burger-width;
    height: 2px;

    content: '';
    animation: animationBurgerTopReverse 1s ease forwards;

    border-radius: 0;
    background: $color-white;

    transition: transform 0.3s $power3-ease-in-out;
}


/**
 * HOVER
 */
.c-burger .c-burger__label {
    transition: transform 0.7s $power3-ease-in-out;
}

.c-burger:hover .c-burger__label {
    transform: rotateY(180deg);
}

.c-burger__input:checked + .c-burger__label {
    transform: rotateY(0deg);
    transition: transform 0s;
}
/**
 * HOVER
 */


.c-burger__input:checked + .c-burger__label .c-burger__burger:before {
    animation: animationBurgerTop 1s ease forwards;
}

.c-burger__label .c-burger__burger:after {
    position: absolute;
    bottom: $burger-gutter;
    left: $burger-gutter;

    width: $burger-width;
    height: 2px;

    content: '';
    animation: animationBurgerBottomReverse 1s ease forwards;

    border-radius: 0;
    background: $color-white;
}

.c-burger__input:checked + .c-burger__label .c-burger__burger:after {
    animation: animationBurgerBottom 1s ease forwards;
}
