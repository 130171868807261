.o-layout {
    position: relative;
    z-index: z-index(above) + 5;

    @include mq(s-tablet) {
        margin-top: 15vh;
    }
}

.o-layout--fh {
    @include mq(s-tablet) {
        min-height: 100vh;
        margin-top: 0;
    }
}

.o-layout--flex {
    @include mq(s-tablet) {
        display: flex;
        flex-direction: column;
    }
}

.o-layout--flex-row {
    @include mq(s-tablet) {
        display: flex;
        justify-content: space-between;
    }
}

.o-layout--art {
    @include mq(wide) {
        min-height: 63vh;
    }
    @include mq(s-tablet) {
        min-height: 73vh;
    }
    @include mq(desktop) {
        min-height: 90vh;
    }
}

.o-layout__column {
    margin-top: 1rem;

    @include mq(s-tablet) {
        margin-top: 0;

        width: 50%;
    }

    &.o-layout__column--one-third {
        @include mq(s-tablet) {
            width: 33.3333%;
        }
    }

    &.o-layout__column--top {
        align-self: flex-start;
    }

    &.o-layout__column--middle {
        align-self: center;
    }

    &.o-layout__column--bottom {
        align-self: flex-end;
    }

    &.o-layout__column--small {
        @include mq(s-tablet) {
            width: 33.3333%;
        }
    }

    &.o-layout__column--large {
        @include mq(s-tablet) {
            width: 66.6666%;
        }
    }
}

.o-layout:not(.o-layout--flex) {
    .o-layout__column {
        @include mq(s-tablet) {
            float: left;
        }
    }
}

.o-layout--first {
    padding-top: 4rem;
    margin: 0;

    @include mq(s-tablet) {
        padding-top: 15vh;
    }
}

.o-layout--feudistudi-wines,
.o-layout--feudistudi-irpinia {
    @include mq(desktop) {
        margin: 30vh 0;
    }
}

.o-layout--newsletter,
.o-layout--contact-header {
    margin-top: 10vh;

    .c-text {
        @include mq(s-tablet) {
            padding-left: 1rem;
        }
    }
}

.o-layout--contact-header {
    margin-top: calc(10vh + 3rem);
}

.o-layout--contact-form {
    margin-top: 3rem;
}

.o-layout--contact,
.o-layout--newsletter {

    @include mq(s-tablet) {
        margin-right: $margin-left-base;
        margin-left: $margin-left-base;
    }

    @include mq(desktop) {
        display: flex;
        flex-direction: column;
    }

    .c-title__title {
        margin-top: 0.5rem;
    }
}

.o-layout--contact-form {
    @include mq(desktop) {
        margin-top: 6vh;
    }
}

.o-layout__column--group-members,
.o-layout__column--group-restaurant {

    margin: 2rem $margin-left-base;

    @include mq(s-tablet) {
        padding: 0 0 0 7%;
        width: 100%;
    }

    img {
        max-height: 100px;
        max-width: 200px;
        margin: 2rem 2rem 4rem 0;

        @include mq(s-tablet) {
            float: left;
        }
    }
}

.o-layout__column--group-members{
    @include mq(s-tablet) {
        padding: 0;
    }

    @include mq(desktop) {
        padding: 0 0 0 7%;
    }
}

.o-layout--group-restaurant {
    margin: $margin-left-base $margin-left-base 0;

    img {
        max-height: 100px;
        max-width: 200px;
        margin: 2rem 2rem 2rem 0;
    }
}

.o-layout--feudistudi-map {
    margin: $margin-left-base;
    flex-direction: row;

    @include mq(desktop) {
        margin: 0 10%;
    }
}

.o-layout__column--rel {
    position: relative;
}

.o-layout--map {
    margin-top: 3rem;
}

.o-layout--contact-inner,
.o-layout--group-awards,
.o-layout--flex-row {
    margin-top: 0;
}

.o-layout--store-locator {
    transition: transform 0.7s $power3-ease-in-out;

    @include mq(desktop) {
        transform: translateY(-10vh);

        margin: 0 floor($margin-left-base / 2);
    }
}

.o-layout--virgo-store-locator {
    transform: translateY(0);
}

.o-layout--result-store-locator {
    position: relative;
    margin-top: 0;

    transition: transform 0.7s $power3-ease-in-out, opacity 0.7s $power3-ease-in-out;

    @include mq(s-tablet) {
        margin: 0 $margin-left-base;
    }

    @include mq(desktop) {
        transform: translateY(-10vh);
        margin: 0 $margin-left-base;
    }

    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: $margin-left-base;
        width: calc(100% - #{$margin-left-base * 2});
        height: 1px;
        background-color: $color-primary;

        transform: scale(1, 1);
        transform-origin: 0% 50%;

        transition: transform 0.7s $expo-ease-in-out 0.5s;

        @include mq(l-tablet) {
            //width: calc(100% - #{floor($margin-left-base / 2)});
            left: 0;
            width: 100%;
        }
    }
}

.o-layout--result-virgo-store-locator {
    opacity: 0;

    &::after {
        transform: scale(0, 1);
    }
}