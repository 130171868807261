.c-radio__item {
    position: relative;
}

.c-radio__input {
    position: absolute;
    z-index: z-index(above);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.c-radio__label {
    padding: 0 20px 0 40px;
    margin: 1rem 0 2rem 0;

    position: relative;

    &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 10px;
        width: 12px;
        height: 12px;
        border: 1px solid $color-primary;
        border-radius: 50%;
        transform: translateY(-50%);
    }
}

.c-radio__input:checked + .c-radio__label {
    &::after {
        background-color: $color-primary;
    }
}

.c-radio.c-radio--text-only {
    text-align: center;

    .c-radio__label {
        padding: 0;
        margin: 1rem auto 0;
        display: inline-block;

        &::after {
            display: none;
        }

        &::before {
            position: absolute;
            content: '';
            top: 100%;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: $color-primary;

            transform: scale(0, 1);
            transform-origin: 0% 50%;
            transition: transform 0.7s $power3-ease-in-out;
        }
    }

    .c-radio__input:checked + .c-radio__label {
        &::before {
            transform: scale(1, 1);
            transition: transform 0.7s $power3-ease-in-out;
        }
    }
}