$loader-width: 50px;

.o-friendly-loader {
    position: fixed;
    z-index: z-index(topbar) - 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;

    display: flex;
    justify-content: center;
    align-items: center;
}

.o-friendly-loader__outer {
    transform: scale(1, 1);
    transition: transform 0.7s $power3-ease-in-out 0s;
}

.o-friendly-loader__inner {
    height: $loader-width;
    width: $loader-width;

    animation: loader-1-1 4.8s linear infinite;
}

.o-friendly-loader__ring {
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: $loader-width;
    width: $loader-width;
    clip: rect(0, $loader-width, $loader-width, #{$loader-width / 2});

    animation: loader-1-2 1.2s linear infinite;

    &::after {
        content: "";
        position: absolute;
        top: 0; left: 0;
        bottom: 0; right: 0;
        margin: auto;
        height: $loader-width;
        width: $loader-width;
        clip: rect(0, $loader-width, $loader-width, #{$loader-width / 2});
        border: 1px solid $color-primary;
        border-radius: 50%;

        animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }
}

.o-friendly-loader__dot {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: floor($loader-width / 2);
    height: floor($loader-width / 2);
    transform: translate(-50%, -50%) scale(1, 1);

    transition: transform 0.3s $power3-ease-in-out 0s;

    &::after,
    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-primary;
        border-radius: 50%;
        transform: scale(0.2, 0.2);
        opacity: 0;
    }

    &::after {
        animation: dotPulse 2s infinite ease-out forwards;
    }

    &::before {
        animation: dotPulse 2s infinite ease-out forwards 1s;
    }
}