.o-book-a-visit {
    position: relative;

    margin: 0 $margin-left-base;
}

.o-book-a-visit.is-out {
    // WARINIG
    // Do not apply transition on this element, 'cause there are fixed element inside!
    opacity: 0;
}

.o-book-a-visit.is-in {
    opacity: 1;
    transition: all 3s $power3-ease-out 0.4s;
}