.c-btn-group {
    display: table;
    table-layout: fixed;

    .c-btn {
        display: table-cell;
        width: 100px;
    }
}

.c-btn-group.c-btn-group--full {
    width: 100%;
}

.c-btn-group--wine-panel {
    .c-btn {
        text-align: left;
        white-space: normal;
        padding: 20px 20px;

        color: $color-white;

        @include mq(s-tablet) {
            text-align: center;
            white-space: nowrap;
            padding: $btn-padding;
        }
    }
}