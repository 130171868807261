$size: 14px;
$speed: 0.6s;
$bar-width: ceil($size / 7);
$gutter-width: floor(($size - $bar-width * 3) / 2);
$bar-color: white;

.c-audio-toggle {
    position: relative;

    width: $size;
    height: 12px;
    margin: 7px auto; // Just for presentation

    cursor: pointer;

    border: 0;
    background-color: transparent;
}

.c-audio-toggle__bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: $bar-width;

    background-color: $bar-color;

    transform-origin: 50% 100%;

    &:nth-child(2) {
        left: $bar-width + $gutter-width;
    }

    &:nth-child(3) {
        left: $bar-width * 2 + $gutter-width  * 2;
    }
}

.c-audio-toggle__file {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    opacity: 0;
}