.o-bottle {
    position: absolute;
    z-index: -1;
    top: 40%;
    left: 32.5%;

    width: 35%;

    //transform: translate(-50%, 10%);

    @include mq(l-tablet) {
        top: 30%;
        left: 30%;
        //transform: translate(-50%, 0%);
        width: 40%;
    }
}

.o-bottle--back {
    z-index: -1;
}

.o-bottle--front {
    z-index: 10;
}
