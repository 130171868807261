.o-svg-map {
    position: relative;

    @include mq(s-tablet) {
        margin: 2rem;
    }
}

.o-svg-map__svg {
    width: 100%;
}

.o-svg-map__area {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;

    &.has-hover,
    &.is-active {
        z-index: 2;

        .o-svg-map__path {
            z-index: 2;

            stroke: $color-primary;
        }
    }
}

.o-svg-map__marker {
    pointer-events: none;

    fill: $color-primary;
}

.o-svg-map__label {
    pointer-events: none;

    font-size: 6px;
    letter-spacing: 1px;

    fill: $color-white;
}

.o-svg-map__line,
.o-svg-map__path {
    cursor: pointer;

    fill: none;
    stroke: #443729;
    stroke-miterlimit: 10;
}

.o-svg-map__line {
    stroke-width: 0.5px;
}

.o-svg-map__path {
    position: relative;
    z-index: 1;

    transition: stroke 0.7s ease;

    fill: transparent;
    stroke-width: 2px;
}

.o-svg-map__desc {
    @include boost-performance;

    display: none;

    transform: translate(0, -10%);
    transition: visibility 0s 0.7s, transform 0.7s $power3-ease-out, opacity 0.3s 0s;

    visibility: hidden;
    opacity: 0;

    will-change: transform, opacity;

    @include mq(l-tablet) {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(10%, -50%);
    }

    @include mq(desktop) {
        padding-left: 5%;
    }

    &.is-active {
        display: block;
        visibility: visible;
        opacity: 1;

        transform: translate(0, 0);
        transition: visibility 0s 0s, transform 0.7s $power3-ease-out 0.3s, opacity 0.3s 0.3s;

        @include mq(l-tablet) {
            transform: translate(0, -50%);
        }
    }
}

.o-svg-map__wines {
    margin: 2rem $margin-left-base;

    strong {
        color: $color-primary;
    }

    a {
        color: rgba($color-primary, 0.6);
        margin-left: 1rem;
        transition: color 0.3s ease;

        &:hover {
            color: $color-primary;
        }
    }

    @include mq(s-tablet) {
        margin-left: $margin-left-indented;
        width: 50%;
    }

    @include mq(wide) {
        width: 40%;
        max-width: 700px;
    }
}

.o-svg-map--gdt,
.o-svg-map--taurasi,
.o-svg-map--fiano {
    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.o-svg-map--gdt {
    .o-svg-map__label {
        font-size: 8px;
    }
}

.o-svg-map--taurasi {
    height: 0;
    padding-bottom: 130%;
}

.o-svg-map--gdt {
    height: 0;
    padding-bottom: 100%;
}

.o-svg-map--fiano {
    height: 0;
    padding-bottom: 80%;
}

.o-svg-map--group {
    height: 0;
    padding-bottom: 115%;

    @include mq(s-tablet) {
        padding-bottom: 105%;
    }

    .o-italy {
        height: auto;
        padding-bottom: 0;
    }
}