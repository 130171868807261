.c-overlay-transition {
    position: fixed;
    z-index: z-index(topbar) - 1;
    top: 0;
    left: 0;

    display: flex;
    visibility: hidden;

    width: 100%;
    height: 100%;

    content: '';

    align-items: center;
    justify-content: center;
}

.c-overlay-transition__behind,
.c-overlay-transition__first,
.c-overlay-transition__second {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';
    transform-origin: 50% 100%;

    background-color: $color-black;
}

.c-overlay-transition__behind {
    opacity: 0;
    background-color: $color-black;
}

.c-overlay-transition__first {
    z-index: 2;
    transform: scale(1, 0);

    background-color: rgba(lighten($color-black, 10%), 0.5);
}

.c-overlay-transition__second {
    z-index: 3;
    transform: scale(1, 0);
}

/**
    -----------------   fromCoverToBody   -----------------
 */
.c-overlay-transition.has-fromCoverToBody-in {
    visibility: visible;

    .c-overlay-transition__behind {
        opacity: 1;
        transition: opacity 1s ease-out 0s;
    }

    .c-overlay-transition__first,
    .c-overlay-transition__second {
        transform: scale(1, 1);
    }

    .c-overlay-transition__first {
        transition: transform 1s $power3-ease-out;
    }

    .c-overlay-transition__second {
        transition: transform 1s $power3-ease-out 0.3s;
    }
}

.c-overlay-transition.has-fromCoverToBody-out {
    visibility: visible;

    .c-overlay-transition__behind {
        opacity: 0;
        transition: opacity 0.5s ease-out 0s;
    }

    .c-overlay-transition__first {
        visibility: hidden;
    }

    .c-overlay-transition__first,
    .c-overlay-transition__second {
        transform: scale(1, 0);
        transform-origin: 50% 0%;
        transition: transform 1s $power3-ease-out;
    }

}

/**
-----------------   fromBodyToCover   -----------------
*/
.c-overlay-transition.has-fromBodyToCover-in {
    visibility: visible;

    .c-overlay-transition__behind {
        opacity: 1;
        transition: opacity 1s ease-out 0s;
    }

    .c-overlay-transition__first,
    .c-overlay-transition__second {
        transform: scale(1, 1);
        transform-origin: 50% 0%;
    }

    .c-overlay-transition__first {
        transition: transform 1s $power3-ease-out;
    }

    .c-overlay-transition__second {
        transition: transform 1s $power3-ease-out 0.3s;
    }
}

.c-overlay-transition.has-fromBodyToCover-out {
    visibility: visible;

    .c-overlay-transition__behind {
        opacity: 0;
        transition: opacity 0.5s ease-out 0.1s;
    }

    .c-overlay-transition__first {
        visibility: hidden;
    }

    .c-overlay-transition__first,
    .c-overlay-transition__second {
        transform: scale(1, 0);
        transform-origin: 50% 100%;
        transition: transform 1s $power3-ease-out;
    }

}

/**
-----------------   fade   -----------------
*/

.c-overlay-transition.has-fade-in {
    visibility: visible;

    .c-overlay-transition__behind {
        opacity: 1;
        transition: opacity 1s ease-out 0s;
    }
}

.c-overlay-transition.has-fade-out {
    visibility: visible;

    .c-overlay-transition__behind {
        opacity: 0;
        transition: opacity 0.5s ease-out;
    }

}