.c-menu-item {
    @include boost-performance();
    will-change: transform;

    text-transform: uppercase;
    display: block;

    transform-origin: 50% 50%;

    @include mq(l-tablet) {
        height: 100%;
        margin: 5%;

        display: flex;
        align-items: center;
    }
}

.c-menu-item__inner {
    width: 100%;
    max-width: 200px;
}

.c-menu-item__label {
    margin-bottom: 1rem;
    color: $color-primary;

    font-size: rem(14px);

    transition: color 0.3s ease;

    @include mq(desktop) {
        color: lighten($color-black, 35%);

        font-size: rem(20px);

        .no-touchevents .is-current &,
        .no-touchevents .has-hover & {
            color: $color-primary;
        }
    }
}

.c-menu-item__num,
.c-menu-item__label {
    @include mq(desktop) {
        display: block;
    }
}

.c-menu-item__name {
    margin-top: floor(1rem / 2);

    font-size: rem(23px);
    line-height: 1;

    position: relative;

    @include mq(s-tablet) {
        margin-top: 1rem;

        font-size: rem(28px);
    }

    @include mq(l-tablet) {
        margin-top: 0;
    }

    .no-touchevents & {
        &::after {
            position: absolute;
            content: '';
            top: auto;
            bottom: -20px;
            left: 0;
            height: 2px;
            width: 60px;
            background-color: $color-primary;

            transform: scale(0, 1);
            transform-origin: 0 50%;
            transition: transform 0.3s $power3-ease-in-out;
        }
    }

    .no-touchevents .o-menu__item.has-hover:not(.is-current) & {
        &::after {
            transform: scale(1, 1);
        }
    }
}

.o-menu__item.is-current {
    .c-menu-item {
        cursor: default !important;
    }
}