.o-corsia {
    position: absolute;
    z-index: 3;
    top: auto;
    bottom: -5vh;
    left: 50%;
    width: 110%;

    overflow: hidden;

    transform: translateX(-50%);

    pointer-events: none;

    @include mq(l-tablet) {
        width: 40%;
    }
}

.o-corsia__img {
    position: absolute;
    z-index: 10;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;

    height: 55vh;

    transition: transform 0.3s linear;
    will-change: transform;


}