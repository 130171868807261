[data-scrollbar] {
    display: block;
    position: relative;
}

[data-scrollbar] .scroll-content {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform;
}

[data-scrollbar].sticky .scrollbar-track {
    background: hsla(0, 0%, 87%, .75);
}

[data-scrollbar] .scrollbar-track {
    position: absolute;
    opacity: 0;
    z-index: 1;
    transition: opacity .5s ease-out, background .5s ease-out;
    background: none;

    user-select: none;
}

[data-scrollbar] .scrollbar-track.show,
[data-scrollbar] .scrollbar-track:hover {
    opacity: 1
}

[data-scrollbar] .scrollbar-track:hover {
    background: hsla(0, 0%, 87%, .75);
}

[data-scrollbar] .scrollbar-track-x {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px
}

[data-scrollbar] .scrollbar-track-y {
    top: 0;
    right: 0;
    width: 8px;
    height: 100%;
}

[data-scrollbar] .scrollbar-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, .5);
    border-radius: 4px
}

[data-scrollbar] .overscroll-glow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}