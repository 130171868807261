.c-search-icon {
    margin: 0;
    padding: 0 $burger-gutter;

    color: $color-white;
    border: 0;
    background-color: transparent;

    font-size: rem($burger-height);
    line-height: $burger-height  + ($burger-gutter * 2);

    cursor: pointer;

    transition: color 0.3s ease;

    &:hover {
        color: $color-primary;
    }

    .o-ico--search {
        display: inline-block;
        margin-top: 10px;

        @include mq(l-tablet) {
            margin-top: 0;
        }
    }
}
