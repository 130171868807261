.o-menu {
    position: fixed;
    z-index: z-index(menu);
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    color: $color-white;

    visibility: hidden;

    &:not(.no-transition) {
        transition: opacity 0.5s 0s, visibility 0s 1.5s;
    }

    @include mq(l-tablet) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        overflow-y: hidden;
    }

    // Target per fallback Firefox perchè non funziona clip-path con le shape
    @supports (-moz-appearance: meterbar) {
        opacity: 0;
        transition: visibility 0s 0.5s, background-color 0s linear 0s,
            opacity 0.5s ease 0s;
    }
}

.o-menu__contacts {
    position: relative;
    width: 100%;
    height: 350px;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.3s ease;
    .o-layout--contact-header {
        margin-top: 0px;
    }
}

.o-menu__inner {
    position: relative;
    z-index: 1;
    padding: $burger-height * 8 0 0;
    margin: 0 calc(#{$burger-width + ($burger-gutter * 2)} + 5vw);

    @include mq(l-tablet) {
        display: flex;
        padding: 0;

        width: 100%;
        height: 100%;
        margin: 0 auto;

        align-items: flex-start;
        justify-content: space-around;
    }

    @include mq(desktop) {
        width: 90%;
    }
}

.o-menu__item {
    padding: 1rem 0;
    perspective: 700px;
    perspective-origin: 50% 50%;

    opacity: 0;

    @include mq(l-tablet) {
        height: 100%;
        padding: 0;
        flex: 1 0 auto;
    }

    @include mq(desktop) {
        transform: translateY(30px);
    }
}

.o-menu__item--group {
    position: relative;
    text-transform: uppercase;
    font-size: rem(16px);
    line-height: 1;
    margin: 3rem 0;
    padding: 1rem 0;

    border-bottom: 1px solid $color-primary;

    .o-ico {
        position: absolute;
        top: 50%;
        left: auto;
        right: 0;

        transform: translateY(-50%);
    }
}

.o-menu__item--lang {
    text-transform: uppercase;
    color: $color-primary;
    padding: 0.5rem 0;
}

.o-menu__bgcontainer,
.o-menu__img {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    pointer-events: none;

    transform: translateZ(0);
}

.o-menu__img {
    position: absolute;
    background-size: cover;
    background-position: center center;

    clip-path: circle(0 at 20px 20px);

    @include mq(l-tablet) {
        clip-path: circle(0 at 40px 40px);
    }

    @supports (-moz-appearance: meterbar) {
        clip-path: none;
    }
}

.o-menu:not(.no-transition) .o-menu__img {
    transition: clip-path 0.8s $power3-ease-in-out;
}

.o-menu__gradient {
    position: fixed;
    z-index: z-index(menu) + 1;
    top: auto;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    pointer-events: none;

    opacity: 0;

    transition: opacity 0.5s ease;

    background-image: linear-gradient(
        to top,
        $color-black 0%,
        rgba($color-black, 0) 20%,
        rgba($color-black, 0) 80%,
        $color-black 100%
    );

    @include mq(desktop) {
        display: none;
    }
}

.o-menu.is-open {
    visibility: visible;
    opacity: 1;

    background-color: lighten($color-black, 10%);

    transition: visibility 0s 0s, background-color 0.3s linear 0.5s;

    @supports (-moz-appearance: meterbar) {
        opacity: 1;
        transition: visibility 0s 0s, background-color 0.3s linear 0.5s,
            opacity 0.5s ease;
    }

    .o-menu__img {
        clip-path: circle(#{sqrt(20000)}vmax at 0 0);

        @supports (-moz-appearance: meterbar) {
            clip-path: none;
        }
    }

    .o-menu__gradient {
        opacity: 1;
    }

    .o-menu__contacts{
    transition: opacity 0.3s ease 0.5s;
      opacity: 1;
    }
}

.o-menu.no-transition {
    opacity: 0;

    transition: opacity 1s 0s, visibility 0s 1.5s;
}
