.c-read-more {
    position: relative;
}

.c-read-more__inner {
    overflow: hidden;
    transition: height 0.5s $power3-ease-in-out;
}

.c-read-more__inner.u-invisible {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.c-read-more.is-out {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0s;
}

.c-read-more.is-in--art {
    opacity: 1;
    transform: translateY(0);
    transition: all 3s $power3-ease-out 1.2s;
}