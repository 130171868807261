.o-lang {
    &:before {
        position: absolute;
        visibility: hidden;
        content: '#{$burger-width + ($burger-gutter * 4)}';
    }
}

.o-lang__active,
.o-lang__unactive {
    display: inline-block;
}

.o-lang__unactive {
    overflow: hidden;

    width: 0;
    float: left;

    white-space: nowrap;
    text-overflow: ellipsis;

    transition: width 0.7s $power3-ease-in-out;

    will-change: width;

    .o-lang__link {
        opacity: 0;

        transition: all 0.3s ease;

        &:hover {
            color: $color-primary;
        }
    }

    &.is-hover {
        .o-lang__link {
            opacity: 1;

            &:nth-child(1) {
                transition: opacity 0.3s ease-in 0.2s, color 0.3s ease;
            }

            &:nth-child(2) {
                transition: opacity 0.3s ease-in 0.3s, color 0.3s ease;
            }

            &:nth-child(3) {
                transition: opacity 0.3s ease-in 0.4s, color 0.3s ease;
            }

            &:nth-child(4) {
                transition: opacity 0.3s ease-in 0.5s, color 0.3s ease;
            }

            &:nth-child(5) {
                transition: opacity 0.3s ease-in 0.6s, color 0.3s ease;
            }
        }
    }
}

.o-lang__link {
    display: inline-block;

    height: $burger-height  + ($burger-gutter * 2);
    width: $burger-width + ($burger-gutter * 4);
    line-height: $burger-height  + ($burger-gutter * 2);

    text-transform: uppercase;
}
