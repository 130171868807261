.c-navi-slider {
    position: absolute;
    z-index: z-index(above) + 3;
    top: auto;
    bottom: 2rem;
    left: 0;

    width: 27vw;

    cursor: pointer;

    color: $color-white;
    border: 0;
    background-color: transparent;

    @include mq(l-tablet) {
        top: 50%;
        bottom: auto;

        transform: translateY(-50%);
    }
}

.c-navi-slider--prev {
    left: 6vw;

    text-align: left;

    @include mq(l-tablet) {
        text-align: center;
    }

    .c-navi-slider__sep {
        transform: rotateY(180deg);
    }
}

.c-navi-slider--next {
    right: 6vw;
    left: auto;

    text-align: right;

    @include mq(l-tablet) {
        text-align: center;
    }
}

.c-navi-slider__chapter,
.c-navi-slider__num,
.c-navi-slider__sep {
    display: block;
    text-transform: uppercase;
}

.c-navi-slider__num {
    padding: rem(24px) 0;

    transition: opacity 0.3s ease-in-out;
    letter-spacing: 1px;

    font-size: rem(10px);

    @include mq(s-tablet) {
        padding: 0;
        font-size: rem(14px);
    }

    @include mq(l-tablet) {
        padding: rem(24px) 0;
        letter-spacing: 5px;

        opacity: 0.5;

        font-size: rem(24px);
    }
}

.c-navi-slider__chapter {
    padding: rem(24px) 0;

    transition: opacity 0.3s ease-in-out;
    letter-spacing: 1px;

    opacity: 0.5;

    font-size: rem(15px);

    @include mq(s-tablet) {
        padding: rem(10px) 0;
    }

    @include mq(l-tablet) {
        padding: rem(24px) 0;
    }
}

.c-navi-slider__sep {
    position: absolute;
    top: 50%;

    width: 50%;
    height: 1px;

    background-color: $color-primary;

    @include mq(l-tablet) {
        position: relative;
        top: 0;

        height: auto;
        margin: auto;

        background-color: transparent;
    }

    .c-navi-slider--prev & {
        right: 0;
        left: auto;
    }

    .c-navi-slider--next & {
        right: auto;
        left: 0;
    }
}

.c-navi-slider__begin,
.c-navi-slider__end,
.c-navi-slider__top,
.c-navi-slider__bottom {
    position: absolute;

    display: block;

    width: 100%;
    height: 1px;

    background-color: $color-primary;

    will-change: transform;

    transition: transform 1s $power3-ease-out 0.2s;
}

.c-navi-slider__begin {
    left: 0;

    width: 70%;

    transform-origin: 100% 50%;
    transition: transform 0.2s $power3-ease-in 0s;
}

.c-navi-slider__end {
    right: 0;
    left: auto;

    width: 30%;

    transform-origin: 100% 50%;
}

.c-navi-slider__top,
.c-navi-slider__bottom {
    right: 1px;
    left: auto;

    width: 8%;

    transform-origin: 100% 50%;
}

.c-navi-slider__top {
    transform: rotate(45deg) scale(0, 1);
}

.c-navi-slider__bottom {
    transform: rotate(-45deg) scale(0, 1);
}


.c-navi-slider:hover {
    .c-navi-slider__begin {
        transform: scale(0.8, 1);
        transition: transform 0.3s ease-in-out 0s;
    }

    .c-navi-slider__top {
        transform: rotate(45deg) scale(1, 1);
    }

    .c-navi-slider__bottom {
        transform: rotate(-45deg) scale(1, 1);
    }

    .c-navi-slider__end {
        transform: scale(0.5, 1);
    }

    .c-navi-slider__num,
    .c-navi-slider__chapter {
        opacity: 1;
    }
}
