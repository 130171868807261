$counter-width: 150px;

.c-counter {
    position: relative;

    width: $counter-width;

    margin: 0 auto;
}

.c-counter__input {
    width: $counter-width;

    text-align: center;

    color: $color-white;
    border: 0;
    background-color: transparent;

    font-size: rem(36px);
}

.c-counter__btn {
    position: absolute;
    top: 50%;

    cursor: pointer;
    transform: translateY(-50%);

    color: $color-white;
    border: 0;
    background-color: transparent;

    font-size: rem(22px);
}

.c-counter__btn--add {
    right: 1rem;
    left: auto;
}

.c-counter__btn--remove {
    right: auto;
    left: 1rem;
}
