.c-popover {
    margin-top: 1rem;
    transition: z-index 0s 1.2s;
    text-transform: uppercase;
    border-bottom: 1px solid rgba($color-white, 0.5);
    @include mq(desktop) {
        position: relative;
        z-index: 1;
        height: 40px;
    }
    @include mq(l-tablet) {
        height: 50px;
        margin-top: 0;
    }
}

.c-popover__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    left: auto;
}

.c-popover__inner {
    padding: 0 5%;
    text-align: center;
    color: $color-white;
    position: relative;
    z-index: 2;
    @include mq(desktop) {
        width: calc(100% - 2rem); //position: absolute;
        //top: 0;
        margin-left: 1.5rem;
        padding: 1rem 0;
        text-align: left;
    }
    .c-radio {
        text-align: left;
    }
    .c-radio--text-only {
        text-align: center;
    }
    .c-calendar {
        text-align: center;
    }
}

.c-popover__close {
    margin-bottom: 2rem;
    text-transform: uppercase;
    color: $color-primary;
    border: 0;
    background-color: transparent;
    font-size: rem(12px);
}

.c-popover__visual {
    height: 100%;
    @include mq(s-tablet) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.c-popover__placeholder {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    opacity: 0.5;
    &.has-result {
        .c-popover__placeholder-result {
            opacity: 1;
        }
        .c-popover__label {
            opacity: 0;
        }
    }
}

.c-popover__label {
    white-space: nowrap;
    &.is-invalid {
        border-bottom: 1px solid $color-error;
    }
}

.c-popover__placeholder-result {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
}

.c-popover__tooltip {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100vh;
    transition: opacity 0.5s ease 0.5s, visibility 0s 1.2s;
    opacity: 0;
    background-color: $color-black;
    @include mq(desktop) {
        position: absolute;
        z-index: z-index(base);
        top: 50%;
        left: 50px;
        width: 400px;
        height: 170px;
        transform: translateY(-50%);
        background-color: transparent;
    }
    .o-layout{
        min-height: 100%;
        // height: 100%;
        margin-top: 0;
        display: flex;
        visibility: hidden;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        @include mq(desktop) {
            margin-top: 0;
        }
    }
    &::after,
    &::before {
        position: absolute;
        z-index: z-index(base) + 2;
        top: 1px;
        right: 2px;
        left: 19px;
        width: calc(100% - 22px);
        height: 20px;
        content: '';
        @include mq(desktop) {
            background-image: linear-gradient(to bottom, $color-black 0%, rgba(0, 0, 0, 0) 100%);
        }
    }
    &::before {
        top: auto;
        bottom: 3px;
        @include mq(desktop) {
            background-image: linear-gradient(to top, $color-black 0%, rgba(0, 0, 0, 0) 100%);
        }
    }
}

.c-popover__body {
    position: relative;
    z-index: 2;
    display: flex;
    visibility: hidden;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    // height: 100%;
    transition: all 1.2s ease;
    opacity: 0;
}

.c-popover__tooltip-svg {
    transition: transform 0.6s $power3-ease-in 0s;
    transform: translateX(50px);
    @include full-abs;
    polygon {
        transition: stroke-dashoffset 0.9s $power3-ease-in-out, fill 0.9s $power3-ease-out 1.2s;
        fill: transparent;
        stroke: $color-primary;
        stroke-dasharray: 1300;
        stroke-dashoffset: 1300;
        stroke-miterlimit: 10;
        stroke-width: 1px;
    }
}

.c-popover__tooltip-shape {
    z-index: z-index(base);
    @include full-abs;
}

.c-popover__result,
.c-popover__block-message {
    max-width: 400px;
    margin-bottom: 1rem;
    @include mq(s-tablet) {
        margin-left: 40px;
        margin-bottom: 0;
    }
}

.c-popover__block-message {
    opacity: 0.5;
    color: $color-white;
}

.c-popover [data-scrollbar],
.c-popover .scroll-content {
    position: absolute;
    z-index: z-index(base) + 1;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
}

.c-popover .scrollbar-track {
    visibility: hidden;
}

.c-popover.is-open {
    z-index: z-index(menu);
    transition: z-index 0s 0s;
    .c-popover__placeholder {
        opacity: 1;
        color: $color-primary;
    }
    .c-popover__tooltip {
        visibility: visible;
        transition: opacity 0.5s ease 0s, visibility 0s 0s;
        opacity: 1;
    }
    .c-popover__tooltip-svg {
        transition: transform 1.2s $power3-ease-out 0s;
        transform: translateX(0);
        polygon {
            transition: stroke-dashoffset 0.9s $power3-ease-in-out, fill 0.9s $power3-ease-out 0s;
            fill: $color-black;
            stroke-dashoffset: 0;
        }
    }
    .c-popover__body {
        visibility: visible;
        transition: all 1.2s ease 0.5s;
        opacity: 1;
    }
}

.c-popover--inline {
    display: block;
    margin-right: 50px;
    text-align: left;
    letter-spacing: 1px;
    border-bottom: 0;
    font-size: rem(12px);
    @include mq(s-tablet) {
        display: inline-block; //text-align: center;
    }
    .c-popover__prefix {
        margin-right: 6px;
        &.is-invalid {
            padding-top: 1px;
            border-bottom: 1px solid $color-error;
        }
    }
    .c-popover__placeholder {
        transition: color 0.3s ease;
        opacity: 1;
        color: $color-primary;
        font-size: rem(14px);
        @include mq(l-tablet) {
            font-size: rem(12px);
        }
        &:hover {
            color: lighten($color-primary, 20%);
        }
    }
    .c-plus {
        right: -20px;
        @include mq(l-tablet) {
            right: -20px;
            left: auto;
            margin-left: 10px;
        }
    }
}

.c-popover--indented {
    margin-left: 0;
    @include mq(s-tablet) {
        margin-left: $margin-left-contained-element;
    }
}