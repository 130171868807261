.c-logo {
    text-transform: uppercase;
    letter-spacing: 1px;
}

.c-logo--header {
    @include mq-prop(margin-top, $topbar-height, topbar-button-margin);
    margin-left: 5vw;
    font-size: rem(15px);
    float: left;
    line-height: $burger-height  + ($burger-gutter * 2);

    @include mq(s-tablet) {
        margin-left: 0;
        font-size: rem(18px);
    }
}