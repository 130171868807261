.c-parallax-bg {
    position: relative;

    overflow: hidden;
}

.c-parallax-bg__fig {
    position: absolute;
    z-index: z-index(above) - 1;
    top: 0;
    left: 0;

    overflow: hidden;

    min-width: 100%;
    min-height: 100%;

    &::before,
    &::after {
        position: absolute;
        z-index: z-index(above);
        top: auto;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 20%;

        content: '';

        background: linear-gradient(to bottom, rgba($color-black,0) 0%,rgba($color-black,1) 100%);

        @include mq(wide) {
            height: 35%;
        }
    }

    &::before {
        top: 0;
        bottom: auto;

        background: linear-gradient(to top, rgba($color-black,0) 0%,rgba($color-black,1) 100%);
    }
}

.c-parallax-bg__img {
    position: relative;

    min-width: 100%;

    @include boost-performance();
    will-change: transform;
}
