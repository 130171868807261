$icon-width: 50px;

.c-wine-spec {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;

    @include mq(l-tablet) {
        margin-top: 3rem;
    }
}

.c-wine-spec__icon,
.c-wine-spec__description {
    flex: 1 0 auto;
}

.c-wine-spec__icon {
    width: $icon-width;
    max-width: $icon-width;
    max-height: $icon-width;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 80%;
        max-height: 80%;
        flex: 1 0;
    }
}

.c-wine-spec__description {
    line-height: 1.5;
    color: $color-text-dark;
    font-size: rem(16px);
    padding: 0 0 0 1rem;
    width: calc(100% - #{$icon-width});

    @include mq(l-tablet) {
        padding: 0 2rem;
    }

    @include mq(desktop) {
        padding-right: 200px;
        font-size: rem(12px);
    }

    h1, h2, h3 {
        line-height: 1;
        margin: 0;
        font-size: rem(22px);
        color: $color-primary;

        @include mq(l-tablet) {
            font-size: rem(20px);
        }
    }
}