.c-imgbg {
    position: relative;

    display: inline-block;

    width: 100%;

    overflow: hidden;

    @include mq(s-tablet) {
        min-height: 73vh;
    }

    @include mq(wide) {
        min-height: 63vh;
    }
}

.c-imgbg__fig,
.c-imgbg__img,
.c-imgbg__video {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;

    pointer-events: none;
}

.c-imgbg__video {
    z-index: -2;
    display: block;
}

.c-imgbg__fig {
    height: 100%;
    margin: 0;
}

.c-imgbg__img {
    display: none;
    top: 0;

    @include mq(s-tablet) {
        display: block;

        opacity: 0;
        transform: scale(0.9, 0.9) translate(15%, 15%);

        .is-visible & {
            opacity: 1;
            transform: scale(1, 1) translate(20%, 15%);

            transition: opacity 3.5s $power3-ease-out, transform 17s $power3-ease-out;
        }
    }

    @include mq(l-tablet) {
        transform: scale(1, 1) translate(0, 0);

        .is-visible & {
            transform: scale(1, 1) translate(0, 0);
        }
    }

    @include mq(desktop) {
        transform: scale(0.9, 0.9);

        .is-visible & {
            transform: scale(1, 1);
        }
    }
}

.c-imgbg--top {
    .c-imgbg__img {
        top: 0;
        transform: none;
    }
}

.c-imgbg__video {
    position: absolute;
    width: 100%;
}