.o-wine-case {
    position: absolute;
    top: 52%;
    left: 10%;
    width: 80%;
}

.o-wine-case--back {
    z-index: -1;
}

.o-wine-case--front {
    z-index: 1;
}