$item-size: 40px;
$dot-size: 6px;

.c-wine-cat-index {
    position: absolute;
    z-index: z-index(above) + 1;
    top: 0;
    left: 2%;

    height: 100%;
}

.c-wine-cat-index__list {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    height: 100%;

    align-items: center;
    justify-content: center;
}

.c-wine-cat-index__item {
    position: relative;
    display: block;

    width: $item-size;
    height: $item-size;
    margin: 0;
    padding: 0;
}

.c-wine-cat-index__link {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateZ(0);

    span {
        position: absolute;

        visibility: hidden;
    }
}

.c-wine-cat-index__dot {
    position: relative;
    transition: transform 0.3s ease-in-out;

    width: $dot-size;
    height: $dot-size;

    display: inline-block;

    border-radius: 50%;
    background-color: $color-white;
}

.c-wine-cat-index__pulse {
    display: inline-block;

    position: absolute;
    content: '';
    top: 50%;
    left: 50%;

    border-radius: 50%;
    background-color: $color-white;

    width: $item-size;
    height: $item-size;

    transform: scale(0.2, 0.2) translate3d(-50%, -50%, 0);
    transform-origin: 0 0;
}

.c-wine-cat-index__label {
    position: absolute;
    top: 50%;
    left: 0;

    width: 150px;
    padding-left: 100%;

    transition: all 1.5s $power3-ease-out;
    transform: translate3d(-20%, -50%, 0);
    text-transform: uppercase;
    pointer-events: none;

    opacity: 0;

    font-size: rem(12px);
    line-height: 1;
}

.c-wine-cat-index__item.is-active {

    .c-wine-cat-index__link {
        cursor: default;
    }

    .c-wine-cat-index__pulse {
        animation: dotPulseCenter 2s infinite ease-out forwards;
    }

    .c-wine-cat-index__label {
        transform: translate3d(0, -50%, 0);

        opacity: 1;
    }
}

.c-wine-cat-index__item:not(.is-active) {
    .c-wine-cat-index__link {
        &:hover {
            .c-wine-cat-index__dot {
                transform: scale(1.5, 1.5);
            }

            & + .c-wine-cat-index__label {
                transform: translate3d(0, -50%, 0);

                opacity: 1;
            }
        }
    }
}
