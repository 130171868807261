.o-irpinia {
    width: 100%;
    height: 0;
    padding-bottom: 90%;

    margin: auto;

    @include mq(s-tablet) {
        width: 80%;
        padding-bottom: 73%;
    }
}

.o-irpinia__svg {
    fill: none;
    max-width: 100%;
    margin: 0;
    overflow: auto !important;
}

.o-irpinia__region:not(.o-irpinia__region--fill) {
    fill: transparent;
}

.o-irpinia__region {
    stroke: $color-primary;
    stroke-miterlimit: 10;
    stroke-width: 1.5px;

    cursor: pointer;
}

.o-irpinia__region--fill {
    fill: url(#diagonals) inherit;
    stroke-width: 0;

    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.o-irpinia__region-group {
    &:hover,
    &.is-active {
        .o-irpinia__region--fill {
            opacity: 1;
        }
    }
}

.o-irpinia__text {
    font-size: rem(9px);
    fill: $color-primary;
    pointer-events: none;
    text-transform: uppercase;

    @include mq(l-tablet) {
        font-size: rem(7px);
    }
}

.o-irpinia__pattern {
    stroke: rgba($color-white, 0.3);
    stroke-width: 1;
}