.c-scene,
.c-scene__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.c-scene__bg {
    @include boost-performance();

    background-size: cover;
    background-position: center center;
    will-change: transform;

    opacity: 0;

    transform: scale(1.04, 1.04);
}

.c-scene--espressioni {
    .c-scene__bg {
        background-image: url(/assets/images/bg-slider-0.jpg);
    }
}

.c-scene--vini,
.c-scene--luigia {
    .c-scene__bg {
        background-image: url(/assets/images/bg-slider-1.jpg);
    }
}

.c-scene--cantina {
    .c-scene__bg {
        background-image: url(/assets/images/bg-slider-2.jpg);
    }
}

.c-scene--negozio {
    .c-scene__bg {
        background-image: url(/assets/images/bg-slider-4.jpg);
    }
}