.o-cookie-bar {
    position: fixed;
    top: auto;
    bottom: 0;

    height: 18vh;

    z-index: z-index(menu) + 2;
    width: 100%;
    margin: 0;
    padding: 0;
    transition: opacity 0.7s $expo-ease-in-out,
        transform 0.7s $expo-ease-in-out,
        visibility 0.7s;

    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);

    background-color: lighten($color-black, 10%);

    @include mq(s-tablet) {
        height: 10vh;
    }

    @include mq(l-tablet) {
        height: auto;
        width: 25%;
        max-width: 350px;

        box-shadow: 0 0 25px 0 rgba($color-black, 0.75);

        bottom: 1rem;
        left: auto;
        right: 5%;

        border-radius: 10px;
    }
}

.o-cookie-bar__content {
    //@include mq-prop((height, padding-left, padding-right), $frame-weight);

    font-size: rem(7px);
    position: relative;
    float: left;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.o-cookie-bar__text {
    margin: 0;
    font-size: rem(12px);
    padding: 8px 20px 0;
    text-align: center;
    line-height: 1.7;
    letter-spacing: 1px;

    @include mq(l-tablet) {
        padding: 15px 20px;
    }
}

.o-cookie-bar__accept-outer {
    a,
    button {
        //@include mq-prop((height, line-height), $frame-weight);
        position: relative;
        display: inline-block;
        font-size: 1rem;
        font-weight: 700;
        margin: 0;
        padding: 1rem;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        text-transform: uppercase;

        &::after {
            position: absolute;
            content: '';
            z-index: -1;
            left: 0;
            top: 49%;
            height: 1px;
            width: 100%;

            transform-origin: 100% 50%;
            transform: scale(0, 1);
            transition: transform 0.3s $power3-ease-in-out;
        }

        &:hover {
            &::after {
                transform-origin: 0% 50%;
                transform: scale(1, 1);
            }
        }
    }

    a {
        color: $color-primary;

        &::after {
            background-color: rgba($color-primary, 0.3);
        }
    }

    button {
        color: lighten($color-text, 20%);

        &::after {
            background-color: rgba($color-white, 0.3);
        }
    }
}

.o-cookie-bar.is-visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}