.c-wine-bg {
    position: fixed;
    top: 0;
    left: 0;

    overflow: hidden;

    width: 100%;
    height: 100%;
}

.c-wine-bg__img {
    @include boost-performance();
    will-change: transform;

    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 200vw;

}