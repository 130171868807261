.c-covers {
    position: fixed;
    z-index: z-index(above);
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.c-covers__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}