.c-tabs {
    margin: 4rem 0;
}

.c-tabs__list {
    position: relative;

    display: table;

    margin: 0 15px 3rem;
    padding-left: 0;
    width: calc(100% - 30px);

    text-align: center;

    @include mq(s-tablet) {
        display: block;
        width: auto;
        text-align: left;

        margin: 0 $margin-left-indented 3rem;
    }
}

.c-tabs__list.is-in {
    .c-tabs__indicator {
        opacity: 1;
    }

    .c-tabs__heading {
        opacity: 1;

        &:nth-child(1) {
            transition: all 0.7s $power3-ease-in-out 0.7s;
        }

        &:nth-child(2) {
            transition: all 0.7s $power3-ease-in-out 0.9s;
        }

        &:nth-child(3) {
            transition: all 0.7s $power3-ease-in-out 1s;
        }

        &:nth-child(4) {
            transition: all 0.7s $power3-ease-in-out 1s;
        }
    }
}

.c-tabs__list.is-out {
    .c-tabs__indicator {
        opacity: 0;
    }
    .c-tabs__heading {
        opacity: 0;
    }
}


.c-tabs__heading {
    display: table-cell;

    margin: 0 1rem;

    @include mq(s-tablet) {
        display: inline-block;

        margin: 0 3rem;
    }

    > button {
        text-align: left;
    }

    button {
        padding: 0.5rem 0;

        cursor: pointer;
        transition: color 0.3s ease-in-out;
        text-transform: uppercase;

        color: $color-primary;
        border: 0;
        border-bottom: 3px solid transparent;
        background-color: transparent;

        &:hover {
            color: $color-white;
        }
    }

    &:first-of-type {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.c-tabs__heading.is-active {
    button {
        color: $color-white;
    }
}

.c-tabs__body {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 76%;

    @include mq(s-tablet) {
        padding-bottom: 50%;
    }

    @include mq(l-tablet) {
        padding-bottom: 46%;
    }

    @include mq(wide) {
        padding-bottom: 43%;
    }
}

.c-tabs__indicator {
    position: absolute;
    top: 100%;
    left: 0;

    width: 100px;
    height: 3px;

    content: '';
    transition: all 0.9s $expo-ease-in-out;

    background-color: $color-primary;

    @include boost-performance();
    will-change: transform, width;
}