.c-iselect {
    position: relative;

    margin: $margin-left-base;

    text-transform: uppercase;

    line-height: 1.1;

    font-size: rem(25px);

    @include mq(s-tablet) {
        margin: 3rem 0 3rem $margin-left-base;
    }

    @include mq(desktop) {
        font-size: rem(36px);
        margin: 3rem 0 2rem floor($margin-left-base / 2);
    }
}

.c-iselect__placeholder {
    display: inline-block;

    cursor: pointer;
    transition: all 0.7s $power3-ease-out;

    .no-touchevents &:hover {
        .c-iselect__label {
            color: $color-primary;
        }
    }
}

.c-iselect__title,
.c-iselect__message {
    letter-spacing: 2px;

    color: $color-primary;

    font-size: rem(12px);
}

.c-iselect__message {
    opacity: 0;
    visibility: hidden;

    transition: visibility 0s 0.3s, opacity 0.3s 0s;

    &.is-visible {
        opacity: 1;
        visibility: visible;

        transition: visibility 0s 0s, opacity 0.3s 0s;
    }
}

.c-iselect__label {
    transition: color 0.3s ease;

    font-size: rem(25px);

    @include mq(desktop) {
        font-size: rem(36px);
    }
}

.c-iselect__list {
    position: absolute;
    top: 15px;
    left: 0;

    width: calc(100% - 2rem);
    margin: 0 0 50px;
    padding: 0;

    transform: translateY(60px);
    transition: transform 0s 0.7s;

    li {
        cursor: pointer;

        font-size: rem(25px);
        line-height: 1;

        @include mq(desktop) {
            font-size: rem(36px);
        }
    }
}

.c-iselect__options {
    position: absolute;
    top: 13px;
    left: -2px;

    display: inline-block;
    visibility: hidden;

    width: 100%;
    height: 100%;

    opacity: 0;

    transition: visibility 0s 0.7s, opacity 0.5s ease 0.0s;

    &::after,
    &::before {
        position: absolute;
        z-index: 2;
        left: 0;

        width: 100%;
        height: 30px;

        content: '';
        pointer-events: none;
    }

    &::after {
        top: 0;

        background-image: linear-gradient(to bottom, $color-black 0%, rgba(0, 0, 0, 0) 100%);
    }

    &::before {
        top: auto;
        bottom: 0;

        background-image: linear-gradient(to top, $color-black 0%, rgba(0, 0, 0, 0) 100%);
    }
}

.c-iselect [data-scrollbar],
.c-iselect .scroll-content {
    position: absolute;
    z-index: z-index(base) + 1;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);
}

/**
 * IS OPEN
 */
.c-iselect.is-open {
    .c-iselect__placeholder {
        // transform: scale(0.2, 0.2);

        opacity: 0;
    }

    .c-iselect__options {
        visibility: visible;

        opacity: 1;

        transition: visibility 0s 0s, opacity 0.5s ease 0.2s;
    }

    .c-iselect__list {
        transform: translateY(0px);
        transition: transform 1s $power3-ease-out;
    }
}


/**
 * IN / OUT
 */
.c-iselect.is-in .c-title__subtitle {
    transition: all 3s $power3-ease-out 0.7s;
    transform: translateY(0);

    opacity: 1;
}

.c-iselect.is-out .c-title__subtitle {
    transition: all 0s;
    transform: translateY(20px);

    opacity: 0;
}
