.o-viso {
    position: absolute;
    z-index: 0;
    left: -80%;

    width: 100%;

    pointer-events: none;

    opacity: 0;

    @include mq(l-tablet) {
        left: 0;

        width: 36%;
    }
}

.o-mano-sopra,
.o-dito-sopra {
    position: absolute;
    z-index: 0;
    top: -10%;
    left: -10%;

    width: 180%;

    pointer-events: none;

    opacity: 0;

    @include mq(s-tablet) {
        top: -15%;
        left: 8%;

        width: 130%;
    }

    @include mq(l-tablet) {
        top: -25%;
        left: 28%;

        width: 66.6666%;
    }

    @include boost-performance();
    will-change: transform;
}

.o-dito-sopra {
    z-index: 10;
}

.o-mano-sotto {
    position: absolute;
    z-index: 3;
    top: auto;
    bottom: 0;
    left: 10%;

    opacity: 0;

    width: 130%;

    pointer-events: none;

    @include mq(s-tablet) {
        left: 25%;

        width: 100%;
    }

    @include mq(l-tablet) {
        left: 25%;

        width: 50%;
    }
    @include boost-performance();
    will-change: transform;
}
