.o-luigia__img {
    @include boost-performance();
    will-change: transform;

    position: absolute;
    top: 30%;
    right: -5%;
    left: auto;

    visibility: hidden;

    width: 65%;

    transition: transform 0.3s linear;
    //transform: translateY(-20%);

    opacity: 0;

}

.o-luigia__img--back {
    z-index: -1;
}

.o-luigia__img--front {
    z-index: 1;
}

.o-albero {
    @include boost-performance(false);
    will-change: transform;

    position: absolute;
    z-index: -1;
    top: -10%;
    left: 8%;

    width: 60%;

    transition: transform 0.3s linear;
    //transform: translateY(-50%);
    text-align: center;

    opacity: 0;

}

.o-albero__img {
    width: 100%;

    transition: transform 0.3s linear;

    will-change: transform;
}

.o-ramo,
.o-vite {
    position: absolute;
    z-index: 3;

    pointer-events: none;

    opacity: 0;
}

.o-ramo--top {
    top: 0;
    left: -30%;

    width: 200%;

    @include mq(l-tablet) {
        left: 20%;

        width: 70%;
    }
}

.o-ramo--bottom {
    top: auto;
    bottom: 0;
    left: -40%;

    width: 150%;

    @include mq(l-tablet) {
        left: 5%;

        width: 50%;
    }
}

.o-vite--top {
    top: auto;
    bottom: 80%;
    left: 0;

    width: 140%;

    transform: rotateY(-0deg) rotateX(-180deg) rotateZ(20deg);

    @include mq(l-tablet) {
        left: 50%;

        width: 70%;
    }
}

.o-vite--bottom {
    top: 80%;
    left: 0;

    width: 150%;

    @include mq(l-tablet) {
        top: 80%;
        left: 40%;

        width: auto;
    }
}
