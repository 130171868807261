.c-gallery {
    background: transparent;
    margin: $margin-left-base;
    perspective: 700px;

    @include mq(s-tablet) {
        margin: 0;
    }

    &.is-not-grabbing {
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    &.is-grabbing {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
}

.c-gallery__slides {
    overflow: hidden;
}

.c-gallery__inner {
    display: table;

    @include boost-performance();
    will-change: transform;

    img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}

.c-gallery__slide {
    position: relative;

    display: table-cell;
    overflow: hidden;

    cursor: inherit;

    .c-gallery.is-not-grabbing & {
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    .c-gallery.is-grabbing & {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
}

.c-gallery--art,
.c-gallery--art-intro {
    @include mq(s-tablet) {
        width: 50%;
        height: 0;
        margin: 0 25% 50px;
        padding-bottom: 50%;
    }

    @include mq(wide) {
        width: 40%;
        margin-left: 30%;
        padding-bottom: 40%;
    }
}

.c-gallery--art-intro {
    @include mq(s-tablet) {
        transform: translateY(-50%);
    }
}

.c-gallery--feudistudi-wine {
    width: 30%;
    margin-top: 10vh;

    @include mq(s-tablet) {
        width: auto;
        margin-top: 10vh;

        img {
            height: 50vh;
        }

        .c-gallery__inner {
            width: 100%;
            text-align: center;
        }
    }

    @include mq(l-tablet) {
        img {
            height: 80vh;
        }
    }

    .c-gallery__inner {
        img {
            width: auto;
        }
    }
}

.c-gallery__nav {
    display: flex;

    margin-top: 1rem;

    align-content: center;
    justify-content: center;
}

.c-gallery__indicator {
    position: relative;

    overflow: hidden;

    width: 30px;

    cursor: pointer;
    text-indent: -9999px;

    color: $color-white;
    border: 0;
    background: transparent;

    opacity: 0.5;
    transition: opacity 0.3s ease;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 5px;
        height: 5px;

        content: '';
        transform: translate(-50%, -50%);

        border-radius: 50%;
        background-color: $color-white;
    }

    &.is-active {
        opacity: 1;
    }
}

.c-gallery:not(.c-gallery--feudistudi-wine).is-out {
    .c-gallery__indicator {
        transform: scale(0, 0);
    }

    .c-gallery__slides {
        opacity: 0;
        transform: translateY(150px) rotateY(-30deg);
        transition: all 0s;
    }
}

.c-gallery--feudistudi-wine.is-out {
    .c-gallery__slides {
        opacity: 0.8;
        transform: translateY(150px);
        transition: all 0s;
    }
}

.c-gallery.is-in {
    .c-gallery__slides {
        opacity: 1;
        transform: translateY(0);
        transition: all 3s $power3-ease-out 0.5s;
    }
}

/**
* c-text.is-in--art-intro
*/
.c-gallery.is-in--art-intro .c-gallery__slides {
    opacity: 1;
    transform: translateY(0) rotateY(0deg);
    transition: all 3s $power3-ease-out 0.5s;
}

/**
* c-text.is-in--art
*/
.c-gallery.is-in--art {

    .c-gallery__slides {
        opacity: 1;
        transform: translateY(0) rotateY(0deg);
        transition: all 3s $power3-ease-out 0.7s;
    }

    .c-gallery__indicator {
        transform: scale(1, 1);

        @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
                transition: transform 0.7s $power3-ease-out #{2.5s + $i * 0.1s};
            }
        }
    }
}
