.c-frames {
    position: relative;
    z-index: z-index(above) + 1;

    width: 100%;
    height: 100%;
}

.c-frames__item {
    @include boost-performance();
    position: absolute;
    z-index: z-index(above);
    top: 0;
    width: 80%;
    height: 100%;

    will-change: transform;
    perspective: 1700px;

    @include mq(l-tablet) {
        width: $cover-frame-width;
    }
}

.c-frames__item--prev,
.c-frames__item--next {
    right: 100%;
    left: auto;

    @include mq(l-tablet) {
        right: calc(100% - #{$cover-frame-sides});
    }
}

.c-frames__item--current {
    z-index: z-index(above) + 1;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.c-frames__item--next {
    right: auto;
    left: 100%;

    @include mq(l-tablet) {
        left: calc(100% - #{$cover-frame-sides});
    }

    @include mq(desktop) {
        transform: rotateY(-10deg);
    }
}

.c-frames__item--prev {
    @include mq(desktop) {
        transform: rotateY(10deg);
    }
}

.c-frames__content {
    @include boost-performance;

    position: absolute;
    top: 0;
    left: 0;

    //display: flex;
    //justify-content: center;
    //align-items: center;

    width: 100%;
    height: 100%;

    text-transform: uppercase;

    line-height: 1;

    transition: transform 0.3s linear;
    will-change: transform;
}