$-form-field-validation-result-size: ( null: 10px);
.c-input-text {
    position: relative;
    &.is-invalid {
        label {
            color: $color-error;
        }
        .c-input-text__indicator {
            background-color: $color-error !important;
        }
    }
}

.c-input-text__outer {
    position: relative;
    margin-bottom: 3rem;
}

.c-input-text__input {
    width: 100%;
    padding: 1rem 0;
    color: $color-white;
    text-transform: uppercase;
    outline: none;
    box-shadow: none;
    border: 0;
    background-color: transparent;
     //transition: height 0.3s linear;
}

.c-input-text__textarea {
    @extend .c-input-text__input;
    resize: none;
    overflow: hidden;
    min-height: 51px;
    max-height: 200px;
}

.c-input-text__indicator {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0.5;
    background-color: $color-white;
    transition: all 0.3s ease-in-out;
}

.c-input-text__validation-result,
.c-input-text__label {
    position: absolute;
    top: 0;
    right: auto;
    left: 0;
    transform: translateY(15px);
    transition: all 0.5s $power3-ease-in-out;
}

.c-input-text__label {
    text-transform: uppercase;
    opacity: 0.5;
}

.c-input-text__validation-result {
    @include mq-prop(width, $-form-field-validation-result-size);
    position: absolute;
    left: auto;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
}

.c-input-text__ghost {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
}

.c-input-text.has-focus {
    .c-input-text__label {
        opacity: 1;
        color: $color-primary;
        transform: translateY(-1rem);
    }
    .c-input-text__indicator {
        opacity: 1;
        background-color: $color-primary;
    }
}

.c-input-text.c-input-text--winery {
    @include mq(l-tablet) {
        margin: 2rem $margin-left-contained-element * 2 2rem $margin-left-contained-element;
    }
}

.c-input-text.c-input-text--primary {
    .c-input-text__label {
        opacity: 1;
    }
    .c-input-text__indicator {
        background-color: $color-primary;
    }
}

.c-input-text.c-input-text--small {
    .c-input-text__label {
        font-size: rem(12px);
        letter-spacing: 1px;
    }
    .c-input-text__input {
        padding: 0.7rem 0;
    }
    .c-input-text__outer {
        margin-bottom: 2rem;
    }
}