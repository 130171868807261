$discover-icon-width: 50px;

.c-discover-slider {
    position: absolute;
    z-index: z-index(above) + 3;
    top: auto;
    bottom: 1rem;
    left: 50%;
    text-align: center;

    padding: 2rem 0;

    cursor: pointer;

    transform: translateX(-50%);

    color: $color-white;
    border: 0;
    background-color: transparent;

    @include mq(l-tablet) {
        width: 100%;
        bottom: 0;
    }
}

/**
 * LABEL (chapter and loading)
 */
.c-discover-slider__label {
    letter-spacing: 1px;
    word-spacing: 1000px;
    text-transform: uppercase;

    font-size: rem(10px);

    @include mq(s-tablet) {
        font-size: rem(14px);
    }

    @include mq(l-tablet) {
        position: absolute;
        left: 0;
        width: 100%;

        word-spacing: inherit;
        font-size: rem(12px);
        letter-spacing: 2px;
    }

    .no-touchevents & {
        transition: all 0.3s ease;
    }
}

.c-discover-slider__label--loading {
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);

    transition: visibility 0s 0.5s, opacity 0.5s $expo-ease-in 0s, transform 0.5s $expo-ease-in 0s;
}

.c-discover-slider__label--chap {
    transition: visibility 0s, opacity 0.5s $expo-ease-out 0.6s, transform 0.5s $expo-ease-out 0.6s;
}

/**
 * ICON
 */
.c-discover-slider__icon {
    position: absolute;

    top: 35%;
    left: 50%;

    width: $discover-icon-width;
    height: $discover-icon-width;

    transform: translateX(-50%);

    @include mq(l-tablet) {
        top: auto;
        bottom: 100%;
    }
}

/**
 * Arrow
 */
.c-discover-slider__arr-line,
.c-discover-slider__arr-left,
.c-discover-slider__arr-right {
    position: absolute;
    z-index: z-index(above) + 3;
    top: auto;
    bottom: 0;
    left: 50%;

    width: 1px;
    height: 10px;

    content: '';

    background-color: $color-primary;

    transform-origin: 50% 100%;
    transition: transform 0.5s $power3-ease-out 0s;

    will-change: transform;

    @include mq(l-tablet) {
        height: 30px;
    }
}

.c-discover-slider__arr-line {
    transition: all 0.5s ease;

    @include mq(l-tablet) {
        height: $discover-icon-width;
    }
}

.c-discover-slider__arr-left {
    transform: rotate(-45deg) scale(1, 0.6);

    @include mq(desktop) {
        transform: rotate(-45deg) scale(1, 0);
    }
}

.c-discover-slider__arr-right {
    transform: rotate(45deg) scale(1, 0.6);

    @include mq(desktop) {
        transform: rotate(45deg) scale(1, 0);
    }
}


/**
 * Circle Loader
*/
.c-discover-slider__loader-outer {
    transform: scale(0, 0);
    transition: transform 0.7s $power3-ease-in-out 0s;
}

.c-discover-slider__loader {
    height: $discover-icon-width;
    width: $discover-icon-width;

    animation: loader-1-1 4.8s linear infinite;
}

.c-discover-slider__ring {
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: $discover-icon-width;
    width: $discover-icon-width;
    clip: rect(0, $discover-icon-width, $discover-icon-width, #{$discover-icon-width / 2});

    animation: loader-1-2 1.2s linear infinite;

    &::after {
        content: "";
        position: absolute;
        top: 0; left: 0;
        bottom: 0; right: 0;
        margin: auto;
        height: $discover-icon-width;
        width: $discover-icon-width;
        clip: rect(0, $discover-icon-width, $discover-icon-width, #{$discover-icon-width / 2});
        border: 1px solid $color-primary;
        border-radius: 50%;

        animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }
}

.c-discover-slider__dot {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: floor($discover-icon-width / 2);
    height: floor($discover-icon-width / 2);
    transform: translate(-50%, -50%) scale(0, 0);

    transition: transform 0.3s $power3-ease-in-out 0s;

    &::after,
    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-primary;
        border-radius: 50%;
    }

    &::after {
        animation: dotPulse 2s infinite ease-out forwards;
    }

    &::before {
        animation: dotPulse 2s infinite ease-out forwards 1s;
    }
}

/**
 * HOVER
 */
.c-discover-slider:not(.is-loading):not(.is-loading_):not(.is-external):hover {
    .c-discover-slider__arr-line {
        @include mq(desktop) {
            transform: scale(1, 0.7);
            transform-origin: 50% 100%;
        }
    }

    .c-discover-slider__arr-left {
        @include mq(desktop) {
            transform: rotate(-45deg) scale(1, 0.5);
            transform-origin: 50% 100%;
        }
    }

    .c-discover-slider__arr-right {
        @include mq(desktop) {
            transform: rotate(45deg) scale(1, 0.5);
            transform-origin: 50% 100%;
        }
    }

    .c-discover-slider__label {
        color: $color-primary;
    }
}


/**
 * IS LOADING
 */
.c-discover-slider.is-external {
    .c-discover-slider__arr-line,
    .c-discover-slider__arr-right,
    .c-discover-slider__arr-left {
        display: none;

        @include mq(l-tablet) {
            display: block;
        }
    }

    .c-discover-slider__arr-line {
        width: 50%;
        height: 50%;
        left: 25%;
        background-color: transparent;
        border: 1px solid $color-primary;
        transform-origin: 50% 50%;
        transition: all 1.2s $power3-ease-in-out;
    }

    &:hover {
        .c-discover-slider__arr-line {
            transform: rotateZ(180deg);
        }
    }
}

.c-discover-slider.is-loading {
    .c-discover-slider__label--loading {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);

        transition: visibility 0s, opacity 0.5s $expo-ease-in, transform 0.5s $expo-ease-in;
    }

    .c-discover-slider__label--chap {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-10px);

        transition: visibility 0s 0.8s, opacity 0.5s $expo-ease-out 0.3s, transform 0.5s $expo-ease-out 0.3s;
    }

    .c-discover-slider__arr-line {
        transform: scale(1, 0);
        transition: all 0.2s $power3-ease-in-out 0.2s;
        transform-origin: 50% 50%;
    }

    .c-discover-slider__arr-left {
        transform: rotate(-45deg) scale(1, 0);
        transition: all 0.2s $power3-ease-in-out 0s;
        transform-origin: 100% 100%;
    }

    .c-discover-slider__arr-right {
        transform: rotate(45deg) scale(1, 0);
        transition: all 0.2s $power3-ease-in-out 0s;
        transform-origin: 50% 100%;
    }

    .c-discover-slider__loader-outer {
        transform: scale(1, 1);
        transition: transform 0.7s $power3-ease-in-out 0.3s;
    }

    .c-discover-slider__dot {
        transform: translate(-50%, -50%) scale(1, 1);
        transition: transform 0.3s $power3-ease-in-out 0.4s;
    }
}

.c-discover-slider.is-loading_ {
    .c-discover-slider__arr-line {
        transform-origin: 50% 50%;
        transition: transform 0.5s $power3-ease-out 0.5s;
    }
}