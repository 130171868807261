.c-title {
    margin: $margin-left-base * 2 $margin-left-base $margin-left-base;

    text-transform: uppercase;

    line-height: 1.1;

    @include mq(s-tablet) {
        margin: 3rem $margin-left-base;
    }
}

.c-title__title {
    font-size: rem(30px);
    margin-top: 0;

    @include mq(desktop) {
        margin-top: 1rem;

        font-size: rem(36px);
    }
    perspective: 1000px;
}

.c-title__title.is-revealed {
    animation: whiteBlur 2s 1 alternate;
}

.c-title.is-in {
    .c-title__char {
        opacity: 1;
        color: $color-white;

        &::before {
            opacity: 0;
        }
    }
}

.c-title__subtitle {
    letter-spacing: 2px;

    color: $color-primary;

    font-size: rem(12px);
}

.c-title--brand {
    color: $color-primary;
}

.c-title--0-margin {
    @include mq(s-tablet) {
        margin-left: 0;
    }
}

.c-title--no-margin {
    @include mq(s-tablet) {
        margin: inherit;
    }
}

.c-title--small-margin {
    @include mq(s-tablet) {
        margin: floor($margin-left-base / 2);
    }
}

.c-title--art {
    margin: 0 $margin-left-base $margin-left-base;

    @include mq(s-tablet) {
        margin: 3rem $margin-left-base;
    }
}

.c-title.is-in {
    opacity: 1;
    transform: translateY(0);
    transition: all 3s $power3-ease-out 0.3s;
}
.c-title.is-in .c-title__subtitle {
    opacity: 1;
    transform: translateY(0);
    transition: all 3s $power3-ease-out 0.7s;
}

.c-title.is-out {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0s;
}

.c-title.is-out .c-title__subtitle {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0s;
}


/**
 * is-in--art
 */
.c-title.is-in--art {
    opacity: 1;
    transform: translateY(0);
    transition: all 3s $power3-ease-out 0.5s;
}
.c-title.is-in--art .c-title__subtitle {
    opacity: 1;
    transform: translateY(0);
    transition: all 3s $power3-ease-out 2.1s;
}

/**
 * is-in--art-intro
 */
.c-title.is-in--art-intro {
    opacity: 1;
    transform: translateY(0);
    transition: all 3s $power3-ease-out 0.1s;
}
.c-title.is-in--art-intro .c-title__subtitle {
    opacity: 1;
    transform: translateY(0);
    transition: all 3s $power3-ease-out 0.5s;
}

.c-title--small{
    @include mq(wide) {
        max-width: 500px;
    }
}