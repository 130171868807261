.c-modal__body {
    position: fixed;
    z-index: z-index(modal);
    top: 0;
    left: 0;

    display: none;
    visibility: hidden;

    width: 100%;
    height: 100vh;

    opacity: 0;
}

.c-modal__trigger {
    position: relative;
    z-index: z-index(modal) - 1;

    @include mq(s-tablet) {
        z-index: z-index(modal) + 1;
    }
}

.c-modal__header {
    margin-bottom: 2rem;
}

.c-modal__gradient {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';
    opacity: 0;

    background-color: $color-black;

    will-change: transform;

    @include mq(s-tablet) {
        width: 200%;
        height: 200%;

        transition: all 2s $power3-ease-out;

        background: radial-gradient(ellipse at center, rgba($color-black, 1) 0%, rgba($color-black, 0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
}

.c-modal__scroll-container {
    &,
    [data-scrollbar] {
        position: absolute;
        z-index: z-index(above);
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }
}

.c-modal__content {
    position: relative;
    bottom: 10vh;

    min-height: 100vh;

    margin: 0 $margin-left-base;
    padding: 120px 0 150px;

    @include mq(desktop) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        left: 25%;
        margin: 0;

        width: 40%;
    }

    h1,
    h2,
    h3 {
        text-transform: uppercase;

        color: $color-primary;

        font-size: rem(12px);
    }
}

.c-modal__close {
    position: relative;
    float: right;
    background-color: transparent;
    width: 20px;
    height: 20px;
    border: 0;

    &::before,
    &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;

        transform: rotate(45deg);
        background-color: $color-white;
        will-change: transform;
    }

    &::after {
        transform: rotate(-45deg);
    }
}

.c-modal.is-open {
    .c-modal__body {
        display: block;
    }
    .c-modal__gradient {
        opacity: 1;

        @include mq(s-tablet) {
            transform: translate3d(-10%, -10%, 0);
        }
    }
}
