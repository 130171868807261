.c-wine-bottle {
    display: flex;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    &::after {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        margin: auto;

        content: '';
        transition: opacity 0.3s ease 0s;
        transform: translate(10%, 12%);

        opacity: 0;
        background-image: url(/assets/images/bottle_shadow.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 80%;
    }

    .c-wine-slider.is-detail-open & {
        &::after {
            opacity: 1;
            transition: opacity 1.5s ease 0.7s;
        }
    }
}

.c-wine-bottle__img {
    height: 70vh;
    margin-top: 15vh;

    transform: scale(0.8, 0.8);
    transform-origin: 50% 0;

    @include mq(l-tablet) {
        margin-top: 10vh;

        transform: none;
    }
}

.c-wine-bottle__discover {
    position: absolute;
    z-index: z-index(above) + 3;
    top: 60%;
    left: 50%;

    display: table;
    visibility: hidden;

    width: 60%;
    height: 10%;
    padding: 6px;

    transition: transform 0.5s $power3-ease-in, opacity 0.5s $power3-ease-in, visibility 0s 0.5s;
    transform: translate(-50%, 20%);
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;

    opacity: 0;

    font-size: rem(12px);

    align-items: center;
    justify-content: center;

    @include mq(s-tablet) {
        top: 70%;
    }

    @include mq(l-tablet) {
        width: 20%;
    }

    &::after,
    &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: '';
        transition: all 0s;
        pointer-events: none;

        border: 1px solid $color-white;
    }

    &::before {
        z-index: 2;

        border: 3px solid $color-primary;
    }

    &:hover {
        &::after {
            transition: all 0.7s ease;
            transform: scale(1.1, 1.3);

            opacity: 0;
        }

        &::before {
            animation: btnBounce 0.7s 1 ease-out forwards;
        }

        .c-wine-bottle__link {
            transition: background-color 0.7s ease 0.1s;

            background-color: rgba($color-primary, 0.6);
        }
    }
}

.c-wine-bottle__discover.is-active {
    visibility: visible;

    transition: transform 0.5s $power3-ease-out, opacity 0.5s $power3-ease-out, visibility 0s 0s;
    transform: translate(-50%, 0%);

    opacity: 1;
}

.c-wine-bottle__link {
    display: table-cell;

    transition: background-color 0.7s ease 0s;
    text-align: center;
    vertical-align: middle;
}
