.c-arrow-top {
    position: relative;

    width: 40px;
    height: 80px;

    cursor: pointer;

    border: 0;
    background-color: transparent;
}

.c-arrow-top__container {
    position: relative;
    top: 0;

    display: block;

    height: 100%;

    transition: transform 0.9s $power3-ease-in-out;
    transform: translateY(30px) rotateY(1800deg);
}

.c-arrow-top__line {
    height: 30px;

    &,
    &::after,
    &::before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        width: 1px;
        margin: 0 auto;

        content: '';

        background-color: $color-primary;
    }

    &::after,
    &::before {
        height: 15px;

        transform-origin: 50% 0;
    }

    &::after {
        transform: rotate(-45deg);
    }

    &::before {
        transform: rotate(45deg);
    }
}

.c-arrow-top:hover {
    .c-arrow-top__container {
        transform: translateY(0);
    }
    .c-arrow-top__line {
        &::after {
            animation: arrow-left 0.7s $power3-ease-in-out forwards 0.2s;
        }
        &::before {
            animation: arrow-right 0.7s $power3-ease-in-out forwards 0.2s;
        }
    }
}

.c-arrow-top--right {
    transform: rotate(90deg);
}

.c-arrow-top--search {
    position: absolute;
    z-index: 2;
    right: 0;
    left: auto;

    width: 42px;
    height: 100%;

    @include mq(desktop) {
        width: 89px;
    }

    .c-arrow-top__container {
        transform: translateY(0) rotateY(1800deg);

        @include mq(desktop) {
            transform: translateY(30px) rotateY(1800deg);
        }
    }

    .c-arrow-top__line {
        &,
        &::after,
        &::before {
            background-color: $color-white;
        }
    }
}
