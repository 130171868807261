.o-search-input {
    position: relative;

    pointer-events: auto;

    &::after {
        position: absolute;
        top: 100%;
        left: 0;

        width: 100%;
        height: 1px;

        content: '';

        background-color: $color-white;
    }

    input {
        width: 100%;
        padding: 1rem 0;

        text-transform: uppercase;

        color: $color-white;
        border: 0;
        outline: none;
        background-color: $color-black;

        font-size: rem(22px);

        @include mq(desktop) {
            font-size: rem(36px);
        }
    }
}

.o-search-input__autocomplete {
    position: absolute;
    top: 0;
    left: 0;

    overflow-x: hidden;

    width: 100%;
    height: 100%;
    padding: 1rem 0;

    content: '';
    text-align: left;
    white-space: nowrap;
    text-transform: uppercase;
    pointer-events: none;

    color: rgba($color-white, 0.3);

    font-size: rem(22px);

    @include mq(desktop) {
        font-size: rem(36px);
    }

    &::after {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        left: auto;

        width: 100px;
        height: 100%;

        content: '';
        pointer-events: none;

        background: linear-gradient(to left, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
    }
}

.o-search-input__message {
    position: absolute;
    top: 120%;
    left: 0;

    text-transform: uppercase;

    opacity: 0;
    transform: translateY(1rem);

    transition: all 0.5s $power3-ease-in;

    &.is-visible {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.5s $power3-ease-out;
    }
}