.o-topbar {
    position:fixed;
    top: 0;
    left: 0;
    z-index: z-index(topbar);
    display: flex;

    width: 100%;

    @include mq-prop(height, $topbar-height);
    justify-content: space-between;
}

.o-topbar__right {
    @include boost-performance();
    will-change: transform;
}

.o-topbar__group {
    margin: 0;
    padding: 0;

    & > li {
        display: inline-block;
    }
}

.o-topbar__button {
    @include mq-prop(margin-top, $topbar-height, topbar-button-margin);

    width: $burger-width + ($burger-gutter * 2);
    height: $burger-height  + ($burger-gutter * 2);

    text-align: center;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 2px;

    @include mq(s-tablet) {
        float: left;
        width: $burger-width + ($burger-gutter * 4);
    }
}

.o-topbar__button--book-a-visit {
    width: auto;
}

.o-topbar__button--lang {
    @include mq(s-tablet) {
        min-width: $burger-width + ($burger-gutter * 4);
        width: auto;
    }
}

.o-topbar__shadow {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 400px;
    height: 100px;

    background: url(/assets/images/topbar-shadow.png) top left no-repeat;
    opacity: 0;

    transition: opacity 2s ease;

    pointer-events: none;

    &.is-visible {
        opacity: 1;
    }
}

.o-topbar__left {
    flex: 1 0 auto;
}

.o-topbar {
    .c-burger {
        transform: translate3d(0, map_get($topbar-height, mobile) * -1, 0);
        opacity: 0;
        transition: all 0.7s $power3-ease-in-out 0s;

        @include mq(s-tablet) {
            transform: translate3d(0, map_get($topbar-height, s-tablet) * -1, 0);
        }
    }

    .c-logo--header {
        transform: translate3d(0, map_get($topbar-height, mobile) * -1, 0);
        opacity: 0;
        transition: all 0.7s $power3-ease-in-out 0.1s;

        @include mq(s-tablet) {
            transform: translate3d(0, map_get($topbar-height, s-tablet) * -1, 0);
        }
    }

    .c-audio-toggle {
        transform: translate3d(0, map_get($topbar-height, mobile) * -1, 0);
        opacity: 0;
        transition: all 0.7s $power3-ease-in-out 0.1s;

        @include mq(s-tablet) {
            transform: translate3d(0, map_get($topbar-height, s-tablet) * -1, 0);
        }
    }

    .c-search-icon {
        transform: translate3d(0, map_get($topbar-height, mobile) * -1, 0);
        opacity: 0;
        transition: all 0.7s $power3-ease-in-out 0.2s;

        @include mq(s-tablet) {
            transform: translate3d(0, map_get($topbar-height, s-tablet) * -1, 0);
        }
    }

    .c-group-icon__ico {
        transform: translate3d(0, map_get($topbar-height, mobile) * -1, 0);
        opacity: 0;
        transition: all 0.7s $power3-ease-in-out 0.3s;

        @include mq(s-tablet) {
            transform: translate3d(0, map_get($topbar-height, s-tablet) * -1, 0);
        }
    }

    .o-lang {
        transform: translate3d(0, map_get($topbar-height, mobile) * -1, 0);
        opacity: 0;
        transition: all 0.7s $power3-ease-in-out 0.4s;

        @include mq(s-tablet) {
            transform: translate3d(0, map_get($topbar-height, s-tablet) * -1, 0);
        }
    }

    .o-topbar__shadow {
        transform: translate3d(0, map_get($topbar-height, mobile) * -1, 0);
        transition: transform 0.7s $power3-ease-in-out 0s;

        @include mq(s-tablet) {
            transform: translate3d(0, map_get($topbar-height, s-tablet) * -1, 0);
        }
    }

    .o-topbar__button {
        border-right: 1px solid rgba($color-primary, 0.0);
        transition: border-right 0.7s $power3-ease-in-out 0s;

        &:last-child {
            border-right: 0;
        }
    }
}

.o-topbar.is-visible {
    .c-burger,
    .c-logo--header,
    .c-audio-toggle,
    .c-search-icon,
    .c-group-icon__ico,
    .o-lang {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    .o-topbar__shadow {
        transform: translate3d(0, 0, 0);
    }

    .o-topbar__button {
        border-right: 1px solid rgba($color-primary, 0.6);
        transition: border-right 0.7s $power3-ease-in-out 0.3s;

        &:last-child {
            border-right: 0;
        }
    }
}
