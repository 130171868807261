// icons
$font-icon: 'Feudi';

%icon-style {
    font-family: $font-icon;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;

    display: inline-block;

    margin-right: 0;
    margin-left: 0;

    text-transform: none;

    speak: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: $font-icon;
    font-weight: normal;
    font-style: normal;

    src: url('/assets/fonts/Feudi/fonts/#{$font-icon}.eot?-8nuhea');
    src: url('/assets/fonts/Feudi/fonts/#{$font-icon}.eot?#iefix-8nuhea') format('embedded-opentype'),
    url('/assets/fonts/Feudi/fonts/#{$font-icon}.ttf?-8nuhea') format('truetype'),
    url('/assets/fonts/Feudi/fonts/#{$font-icon}.woff?-8nuhea') format('woff'),
    url('/assets/fonts/Feudi/fonts/#{$font-icon}.svg?-8nuhea#icomoon') format('svg');
}

$icons: (
    group: '\e900',
    search: '\e901'
);

// print icons
@each $iconname, $iconcontent in $icons {
    .o-ico--#{$iconname}::before,
    [data-ico~='#{$iconname}']::after {
        content: $iconcontent;
    }
}

.o-ico,
[data-ico]::after {
    @extend %icon-style;
}

// Adjustments
.o-ico {
    font-size: $font-sizes;
}