.c-plus {
    position: absolute;
    top: 50%;
    right: 0;
    //left: 10px;

    width: $plus-size;
    height: $plus-size;

    overflow: hidden !important; // Fix Edge, IE11 bug

    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    transform: translateY(-50%);
    //transform-origin: 50% 50%;
    white-space: nowrap;
    text-indent: 9999px;

    border: 0;
    background-color: transparent;

    font-size: 0;

    @include mq(l-tablet) {
        top: 0;
        right: inherit;

        margin-left: 20px;

        transform: none;
    }

    &::after,
    &::before {
        position: absolute;
        top: 50%;
        left: 0;

        width: 100%;
        height: 1px;

        content: '';

        background-color: $color-white;

        will-change: transform;
    }

    &::after {
        transition: transform 0.5s $power3-ease-in-out;
        transform: rotate(90deg);
    }
}

.no-touchevents .c-plus:not(.is-active):hover {
    transform: rotate(90deg);
}

.c-plus.is-active {
    &::after {
        transform: rotate(0deg);
    }
}

.c-plus--iselect {
    position: relative;
    line-height: 36px;
}


.c-plus--search {
    @include mq-prop(margin-top, $topbar-height, topbar-button-margin);
    position: fixed;
    top: 5px;
    left: auto;

    height: floor($plus-size * 1.5);
    width: floor($plus-size * 1.5);

    transform: rotate(45deg);

    .no-touchevents &:hover {
        transform: rotate(135deg);
    }
}