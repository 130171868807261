.o-italy {
    position: relative;

    width: 100%;
    height: 0;
    padding-bottom: 122%;
}

.o-italy__svg {
    max-width: 100%;
    overflow: auto !important;
}

.o-italy__region {
    fill: none;
    stroke: $color-primary;
    stroke-miterlimit: 10;
    stroke-width:1.5px;
    position: relative;

    &.is-active {
        fill: $color-primary;
    }
}

.o-italy__label {
    pointer-events: none;
    font-size: 11px;
    letter-spacing: 3px;
    fill: $color-primary;
}

.o-italy__dot-1,
.o-italy__dot-2 {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    pointer-events: none;

    border-radius: 50%;

    width: 10px;
    height: 10px;

    transform-origin: 50% 50%;

    opacity: 0;
    background-color: $color-white;

    animation: dotPulse 2s linear 0s forwards infinite;
}

.o-italy__dot-2 {
    animation: dotPulse 2s linear 1s forwards infinite;
}

.o-italy__region--sensitive {
    fill: rgba($color-primary, 0.2);
    cursor: pointer;
    z-index: 10;
    transition: fill 0.3s ease-in-out;

    @include mq(desktop) {
        fill: transparent;
    }

    &:hover {
        fill: $color-primary;
    }
}

.o-italy--thumb {
    .o-italy__svg {
        //margin: 15%;

        @include mq(desktop) {
            //margin: 15% 0 10% 15%;
        }

        .o-italy__region {
            stroke: darken($color-white, 60%);
        }
    }
    .o-italy__dot-1,
    .o-italy__dot-2 {
        top: 57%;
        left: 69%;
    }
}

.o-italy__marker {
    fill: $color-white;
    transform-origin: 50% 50%;
    animation: dotPulse 2s infinite ease-out forwards;
}

.o-italy__city {
    position: absolute;
    color: $color-primary;
    text-transform: uppercase;
    font-size: rem(10px);
    letter-spacing: 2px;
    width: 5px;
    height: 5px;
    background-color: $color-primary;

    &::after {
        position: absolute;
        content: attr(data-name);
    }

    &:before {
        position: absolute;
        content: '';
        width: 85px;
        height: 1px;
        background-color: $color-primary;
        transform-origin: 50% 100%;
    }
}

.o-italy__city--milano {
    top: 13%;
    left: 21%;

    &::after {
        top: -100px;
        left: -50px;
    }

    &::before {
        transform: rotate(-100deg);
        transform-origin: 2px 0;
        top: 2px;
        left: 0;
    }
}

.o-italy__city--venezia {
    top: 18%;
    left: 46%;

    &::after {
        top: 25px;
        left: 65px;
    }

    &::before {
        transform: rotate(13deg);
        transform-origin: 2px 0;
        top: 2px;
        left: 0;
    }
}

.o-italy__city--firenze {
    top: 35%;
    left: 38%;

    &::after {
        top: 60px;
        left: -95px;
    }

    &::before {
        transform: rotate(-43deg);
        transform-origin: 2px 0;
        top: 57px;
        left: -60px;
    }
}

.o-italy__city--roma {
    top: 52%;
    left: 48%;

    &::after {
        top: 70px;
        left: -80px;
    }

    &::before {
        transform: rotate(-45deg);
        transform-origin: 2px 0;
        top: 62px;
        left: -60px;
    }
}

.o-italy__city--napoli {
    top: 62%;
    left: 66%;

    &::after {
        top: 80px;
        left: -80px;
    }

    &::before {
        transform: rotate(-57deg);
        transform-origin: 2px 0;
        top: 69px;
        left: -45px;
    }
}