$close-size: 16px;

.c-btn {
    display: inline-block;

    padding: $btn-padding;

    cursor: pointer;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;

    border: 1px solid transparent;
}

.c-btn--primary {
    transition: background-color 0.3s ease;
    text-transform: uppercase;

    color: $color-text;
    background-color: $color-primary;

    font-size: rem(12px);

    &:hover {
        background-color: lighten($color-primary, 5%);
    }
}

.c-btn--vertical {
    position: relative;

    width: 180px;
    margin-top: -90px;
    margin-left: 55vw;
    padding: 50px 0;

    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    text-align: left;

    color: $color-white;
    border: 0;
    background-color: transparent;

    backface-visibility: hidden;

    @include mq(s-tablet) {
        margin-left: 65%;
    }

    &::before,
    &::after {
        position: absolute;
        top: calc(50% + 1rem);
        left: 0;

        width: 100%;
        height: 1px;

        content: '';
        //transition: transform 0.5s $power3-ease-in-out, opacity 0.2s ease 0.5s;
        transform-origin: 100% 50%;

        background-color: $color-primary;

        will-change: transform, opacity;
    }

    &::after {
        transform: scale(0, 1);
        transition: transform 0.7s $expo-ease-in-out;
    }

    span {
        display: block;

        transition: all 0.5s $power3-ease-in-out;
    }
}

.c-btn--vertical.is-out {
    &::before {
        transform: scale(0, 1);
        transition: all 0s;
    }

    > span {
        transform: translateX(30px);
        opacity: 0;
        transition: all 0s;
    }
}

.c-btn--vertical:not(.is-active) {
    &::before {
        transform: scale(1, 1);
        transition: transform 0.7s $power3-ease-out 2.5s, opacity 0s ease 0s;
    }

    &::after {
        transform: scale(0, 1);
        transition: transform 0s $power3-ease-in-out 0s, opacity 0s ease 0s;
    }

    > span {
        transform: translateX(0);
        opacity: 1;
        transition: all 1s $power3-ease-out 0.3s;
        animation: none;
    }

    &:hover {
        &::before {
            opacity: 0;
            transition: opacity 0.8s ease 0s;
        }

        &::after {
            transform: scale(1, 1);
            transition: transform 0.7s $expo-ease-in-out 0s;
        }

        > span {
            animation: btnVerticalLabelFlip 1.5s $power3-ease-out forwards;
        }
    }
}

.c-btn--vertical.is-closing {
    > span {
        animation: none !important;
        transition: all 1s $power3-ease-out 0s;
    }
}

.c-btn--vertical:not(.is-active).is-in--art {
    > span {
        transition: all 1s $power3-ease-out 2.7s;
    }
}

.c-btn--vertical > .c-btn__close {
    position: absolute;
    top: calc(50% + 1rem);

    pointer-events: none;

    width: $close-size;
    height: $close-size;

    content: '';
    transform: translate3d(-200%, -50%, 0);
    transition: all 0.5s $power3-ease-in-out;

    opacity: 0;

    will-change: transform;

    &::after,
    &::before {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 1px;

        content: '';
        transform: translateY(#{$close-size / 2}) rotate(45deg);
        transform-origin: 50% 50%;

        background-color: $color-white;
    }

    &::after {
        transform: translateY(#{$close-size / 2}) rotate(-45deg);
    }
}

.c-btn--vertical.is-active {
    span {
        transform: translateX(50px);
        //animation: btnVerticalLabelGoAway 0.5s $power3-ease-in-out forwards;

        opacity: 0;
    }

    &::before {
        transform: scale(0.8, 1);
    }

    > .c-btn__close {
        transform: translate3d(0, -50%, 0);
        opacity: 1;
    }
}

.c-btn--one-columns-small {
    width: 100px;
    margin-left: 15%;
    margin-top: 0;

    @include mq(s-tablet) {
        margin-left: 35%;
    }
}

.c-btn--carousel {
    z-index: z-index(above) + 2;
    position: relative;
    top: calc(100% + 1rem);
    left: 50%;
    transform: translate(-50%, 0);

    padding: $btn-carousel-padding;
    will-change: opacity;

    @include mq(l-tablet) {
        position: absolute;
        top: 100%;
        transform: translate(-50%, -50%);
    }
}

.c-btn--transparent {
    @extend .c-btn--carousel;
    background-color: transparent;
    color: $color-primary;
}

.c-btn--back {
    width: 120px;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    svg {
        width: 27px;
        height: 35px;
        transform-origin: 50% 50%;

        * {
            fill: $color-primary;
        }
    }
}

.c-btn--back__icon,
.c-btn--back__label {
    align-self: center;
}

.c-btn--back__icon {
    padding-right: 10px;

    animation: arrowLeftBack 0.7s $power3-ease-in-out forwards;
}

.c-btn--back__label {
    font-size: 12px;
    letter-spacing: 1px;
    color: $color-text-gray;
    flex: 1 0 auto;
    width: 65px;

    transition: transform 0.7s $power3-ease-in-out 0s;

    @include mq(s-tablet) {
        width: 85px;
    }
}

.c-btn--back__line-hover,
.c-btn--back__poly-hover {
    visibility: hidden;
}

.c-btn--wine-detail-close {
    position: fixed;
    z-index: z-index(above);
    top: 10px;
    left: auto;
    right: 2rem;
    line-height: 1;

    @include mq(l-tablet) {
        top: 50%;
        transform: translateY(-50%);
    }

    svg {
         transform: rotate(180deg);
    }

    .c-btn--back__label {
        width: 80px;
    }
}

.c-btn--leaf-close,
.c-btn--thankyou-close {
    position: fixed;
    z-index: z-index(above);
    top: 5vh;
    left: auto;
    right: 5%;
    line-height: 1;

    background-image: url(/assets/images/shadow-leaf-back.png);

    @include mq(s-tablet) {
        top: 15vh;
    }

    @include mq(desktop) {
        background-image: none;
    }
}

.c-btn--thankyou-close {
    @include mq(s-tablet) {
        top: 18vh;
    }
}

.c-btn--dark {
    color: $color-white;
}

.no-touchevents .c-btn--back:hover {
    .c-btn--back__label {
        transform: translate3d(-80px, 0, 0);
        transition: transform 0.7s $power3-ease-in-out 0s;
    }

    .c-btn--back__icon {
        animation: arrowLeft 0.7s $power3-ease-in-out forwards;
    }
}

.c-btn--back.c-btn--right-arrow {
    @include mq(desktop) {
        padding: 1rem 0;
    }

    .c-btn--back__icon {
        animation: arrowRightBack 0.7s $expo-ease-in-out forwards;
    }

    &:hover {
        .c-btn--back__label {
            transform: translate3d(-50px, 0, 0);
            transition: transform 0.7s $power3-ease-in-out 0s;
        }

        .c-btn--back__icon {
            animation: arrowRight 0.7s $expo-ease-in-out forwards;
        }
    }
}

.c-btn--privacy-close {
    margin: $margin-left-base * 2 $margin-left-base $margin-left-base;

    @include mq(s-tablet) {
        margin: 3rem $margin-left-base;
    }
}

.c-btn--disabled {
    cursor: default;
}