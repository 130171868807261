$margin: 18.75vw;
$plus-size: 13px;

.c-accordion {
    margin: 0;
}

.c-accordion__item {
    font-size: rem(14px);

    overflow: hidden;
    position: relative;

    @include mq(l-tablet) {
        width: 70%;
    }

    p {
        padding-bottom: 3rem;
    }
}

.c-accordion__heading {
    font-size: rem(12px);
    text-transform: uppercase;
    margin: 2rem $margin-left-base;
    position: relative;

    @include mq(s-tablet) {
        width: calc(100% - #{$margin * 2});
        margin-left: $margin-left-indented;
    }

    @include mq(l-tablet) {
        width: 100%;
    }

    span {
        position: relative;
        padding: 0.5rem 0;
        display: block;
        width: 90%;

        @include mq(l-tablet) {
            display: inline;
        }

        &::before,
        &::after {
            position: absolute;
            content: '';
            top: auto;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $color-primary;
            opacity: 0.5;
            transform-origin: 0 50%;
        }

        &::after {
            opacity: 1;
            transform: scale(0, 1);

            transition: transform $expo-ease-in-out 0.7s;
        }
    }
}

.c-accordion__body {
    position: relative;
    transition: height 0.7s $power3-ease-in-out;
}

.c-accordion__body.u-invisible {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.c-accordion__heading {
    cursor: pointer;
}

.c-accordion__item.is-active {
    .c-accordion__heading {
        span {
            &::before {
                opacity: 1;
            }
        }
    }
}

.c-accordion__item:not(.is-active):hover {
    .c-accordion__heading {
        span {
            &::after {
                transform: scale(1, 1);
            }
        }
    }
}

.c-accordion.is-out {
    .c-accordion__heading,
    .c-accordion__body {
        opacity: 0;
        transform: translateY(50px);
    }
}


.c-accordion.is-in {

    .c-accordion__heading,
    .c-accordion__body {
        opacity: 1;
        transform: translateY(0);
    }

    .c-accordion__item:nth-child(1) {
        .c-accordion__heading {
            transition: opacity 2.5s $power3-ease-out, transform 2.5s $power3-ease-out;
        }
        .c-accordion__body {
            transition: height 0.7s $power3-ease-in-out,
                opacity 2.5s $power3-ease-out 0.1s,
                transform 2.5s $power3-ease-out 0.1s;
        }
    }

    .c-accordion__item:nth-child(2) {
        .c-accordion__heading {
            transition: opacity 2.5s $power3-ease-out 0.2s, transform 2.5s $power3-ease-out 0.2s;
        }
        .c-accordion__body {
            transition: height 0.7s $power3-ease-in-out,
                opacity 2.5s $power3-ease-out 0.3s,
                transform 2.5s $power3-ease-out 0.3s;
        }
    }

    .c-accordion__item:nth-child(3) {
        .c-accordion__heading {
            transition: opacity 2.5s $power3-ease-out 0.4s, transform 2.5s $power3-ease-out 0.4s;
        }
        .c-accordion__body {
            transition: height 0.7s $power3-ease-in-out,
                opacity 2.5s $power3-ease-out 0.5s,
                transform 2.5s $power3-ease-out 0.5s;
        }
    }
}
