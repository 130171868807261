.c-carousel {
    overflow: hidden;

    width: 100vw;
    margin: 2rem 0;
    padding: 0;

    cursor: -moz-grab !important;
    cursor: -webkit-grab !important;

    perspective-origin: top;
}

.c-carousel__inner {
    @include boost-performance();
    will-change: transform;

    display: table;

    margin: 0;
    padding: 0;
}

.c-carousel__item {
    @include boost-performance();
    will-change: transform;

    display: table-cell;
    overflow: hidden;

    vertical-align: top;

    figure {
        width: 100%;
        height: 0;
        margin: 0;
        padding: 0 0 65%;
    }

    img {
        width: 100%;
    }
}

.c-carousel__figure {
    .c-carousel.is-not-grabbing & {
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    .c-carousel.is-grabbing & {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
}