$margin: $margin-left-indented;

.c-text {
    line-height: 1.7;
    margin: 0 $margin-left-base;
    //margin: 0 $margin-left-base 1rem;

    strong {
        color: $color-primary;
    }

    @include mq(s-tablet) {
        margin: 0;
    }
}

.c-text__link {
    position: relative;
    color: rgba($color-white, 0.5);
    transition: color 0.3s ease-in-out;
    padding: 1rem 0;

    &::before,
    &::after {
        position: absolute;
        content: "";
        top: 80%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-primary;
    }

    &::before {
        transition: none;
    }

    &::after {
        opacity: 0;
        transform: translate3d(-10%, 0, 0) scale(0, 1);
        transform-origin: 0 50%;
        transition: none;
    }

    .no-touchevents &:hover {
        color: $color-white;
        transition: color 1s ease-in-out;

        &::before {
            opacity: 0.5;
            transition: opacity 0.7s $power3-ease-in-out;
        }

        &::after {
            opacity: 1;
            transform: translate3d(0, 0, 0) scale(1, 1);
            transition: transform 0.7s $power3-ease-in-out 0.2s,
                opacity 0.7s $power3-ease-in-out 0s;
        }
    }
}

.c-text--two-columns {
    //margin: 0 $margin-left-base;

    @include mq(s-tablet) {
        width: calc(100% - #{$margin * 2});
        margin-left: $margin-left-indented;
        column-count: 2;
        column-gap: 4rem;

        p:first-child {
            margin-top: 0;
        }
    }
}

.c-text--one-column-big {
    //margin: 0 $margin-left-base;

    @include mq(s-tablet) {
        margin-left: $margin-left-indented;
        width: 50%;
    }

    @include mq(wide) {
        width: 40%;
        max-width: 700px;
    }
}

.c-text--one-column-medium {
    @extend .c-text--one-column-big;

    @include mq(s-tablet) {
        max-width: 340px;
    }

    @include mq(wide) {
        max-width: 500px;
    }
}

.c-text--list {
    ul > li{
        margin-bottom: 5px;
        &::before {
            content: "\2022";
            color: $color-primary;
            font-weight: bold; 
            display: inline-block; 
            width: 1em;
            margin-left: -1em;
        }
    }
}

.ungc-right{
    padding: 0;

    @include mq(desktop) {
        margin-left: -100px;
    }

    @include mq(wide) {
        padding: 0 0 0 12%;
    }

    img{
        margin-left: 0;
        max-width: 300px;
    }
}

.c-text--downloads {
    margin-top: 50px;
    @include mq(wide) {
        padding-left: 12vw;
        margin-top: 0;
    }
    ul > li {
        margin-bottom: 10px;
        &::before {
            display: inline-block;
            background-image: url("/assets/images/download.svg");
            background-size: contain;
            width: 15px;
            height: 15px;
            margin-right: 10px;
            color: #fff;
            content: "";
        }
        a {
            display: inline-block;
            &::after {
                display: block;
                content: "";
                height: 1px;
                background-color: $color-primary;
                width: 0;
                transition: width 0.3s ease-out;
            }
            &:hover::after {
                width: 100%;
            }
        }
    }

    p a {
        display: inline-block;
        color: #a1a1a1;
        &::after {
            display: block;
            content: "";
            height: 2px;
            background-color: $color-primary;
            width: 100%;
        }
    }
}

.c-text--one-column-small {
    @extend .c-text--one-column-big;

    @include mq(s-tablet) {
        width: 60%;
        max-width: 300px;
    }

    @include mq(desktop) {
        width: 70%;
        max-width: 440px;
    }

    @include mq(wide) {
        max-width: 540px;
    }
}

.c-text--group-intro {
    @extend .c-text--one-column-small;

    @include mq(s-tablet) {
        max-width: 500px;
    }
}

.c-text--one-column-full {
    //margin: 0 $margin-left-base;

    @include mq(s-tablet) {
        margin-left: $margin-left-indented;
        width: calc(100% - #{$margin-left-indented * 2});
    }

    @include mq(l-tablet) {
        width: calc(100% - #{$margin-left-indented});
    }

    @include mq(wide) {
        max-width: 700px;
    }
}

.c-text--non-indented {
    margin-left: $margin-left-base;
}

.c-text--indented-inside {
    margin-left: 0;

    @include mq(s-tablet) {
        margin-left: $margin-left-contained-element;
    }
}

.c-text--title-indented-inside {
    @include mq(s-tablet) {
        margin: 0 12vw;
        h3 {
            margin-top: 0;
        }
        p {
            margin-left: ($margin-left-contained-element * 0.5);
        }
    }
}

.c-text--m-top {
    margin-top: 3rem;

    @include mq(desktop) {
        margin-top: 6rem;
    }
}

.c-text--readmore {
    margin-top: 0;

    @include mq(desktop) {
        margin-top: 6rem;
    }
}

.c-text--map {
    margin: 0;

    @include mq(desktop) {
        max-width: 500px;
    }
}

.c-text--opaque {
    opacity: 0.5;
}

.c-text--step {
    color: $color-text-gray;
    margin: 1rem 0;

    @include mq(l-tablet) {
        margin: 0;
    }
}

.c-text--modal {
    margin: 0;
}

.c-text.is-in {
    opacity: 1;
    transform: translateY(0);
    transition: all 3s $power3-ease-out 0.4s;
}

.c-text.is-out {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0s;
}
/**
 * c-text.is-in--art
 */
.c-text.is-in--art {
    opacity: 1;
    transform: translateY(0);
    transition: all 3s $power3-ease-out 0.6s;
}

/**
 * c-text.is-in--art-intro
 */
.c-text.is-in--art-intro {
    opacity: 1;
    transform: translateY(0);
    transition: all 3s $power3-ease-out 0.4s;
}
