.c-wine-gradient {
    position: absolute;
    z-index: z-index(above) + 1;
    top: auto;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 40%;

    pointer-events: none;

    background-image: linear-gradient(to top, $color-black 0%, $color-black 50%, rgba(0, 0, 0, 0) 100%);
}
