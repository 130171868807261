.c-list {
    margin: 0;
    padding: 0;
    line-height: 2;
}

.c-list--inline {
    @include mq(desktop) {
        float: left;
        li {
            display: inline-block;
            vertical-align: top;
            margin-right: 1rem;
            position: relative;

            &:not(:last-child)::after {
                position: absolute;
                content: '';
                top: 43%;
                left: calc(99% + 0.5rem);
                width: 1px;
                height: 1px;
                border-radius: 50%;
                background-color: rgba($color-white, 0.6);

                @include mq(wide) {
                    left: calc(99% + 1rem);
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @include mq(wide) {
        li {
            margin-right: 2rem;
        }
    }
}

.c-list--credits {
    margin-left: 1rem;

}