.o-landscape {
    position: fixed;
    z-index: z-index(loader);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    text-align: center;

    background-color: $color-black;
    display: none;

    svg {
        width: 100px;
        height: 100px;
    }

    path {
        fill: darken($color-white, 50%);
    }
}

.o-landscape.is-landscape {
    display: flex;
    justify-content: center;
    align-items: center;
}